/* eslint-disable import/no-default-export */
const initialState = {
  data: {}
};

export const activities = (state = initialState, action) => {
  switch (action.type) {
    case 'POPULATE_ACTIVITY':
      return Object.assign({}, state, { data: action.payload });
    case 'SET_TIME_STAMP_FOR_EMAIL_TO_SCROLL_TO':
      return Object.assign({}, state, {
        timeStampForEmailToScrollTo: action.payload
      });
    default:
      return state;
  }
};

export default activities;
