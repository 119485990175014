// TODO continue adding to this file
export const routes = Object.freeze({
  ADMIN: {
    basePath: '/admin'
  },
  APP: {
    basePath: '/mobile',
    complaintDrafts: {
      name: 'Complaint Drafts',
      path: '/mobile/submission-drafts'
    },
    dashboard: {
      name: 'Dashboard',
      path: '/mobile/dashboard'
    },
    detections: {
      name: 'Detections',
      path: '/mobile/detections'
    },
    reporting: {
      name: 'Reporting',
      path: '/mobile/reporting'
    }
  },
  COOKIE_POLICY: {
    name: 'Cookie Policy',
    path: '/cookie-policy'
  },
  DOMAIN: {
    basePath: '/domain',
    complaintDrafts: {
      name: 'Complaint Drafts',
      path: '/domain/submission-drafts'
    },
    dashboard: {
      name: 'Dashboard',
      path: '/domain/dashboard'
    },
    detections: {
      name: 'Detections',
      path: '/domain/detections'
    },
    reporting: {
      name: 'Reporting',
      path: '/domain/reporting'
    }
  },
  GRAPH: {
    basePath: '/networks',
    detections: {
      name: 'Detections',
      path: '/networks/app/detections'
    },
    explore: {
      name: 'Explore',
      path: '/networks/app/explore'
    },
    investigations: {
      name: 'Investigations',
      path: '/networks/app/investigations'
    }
  },
  LOGIN: {
    basePath: '/login'
  },
  MARKETPLACES: {
    basePath: '/marketplaces',
    complaintDrafts: {
      name: 'Complaint Drafts',
      path: '/marketplaces/submission-drafts'
    },
    dashboard: {
      name: 'Dashboard',
      path: '/marketplaces/dashboard'
    },
    detections: {
      name: 'Detections',
      path: '/marketplaces/detections'
    },
    reporting: {
      name: 'Reporting',
      path: '/marketplaces/reporting'
    }
  },
  SEARCH_ENGINE: {
    basePath: '/search-engines',
    complaintDrafts: {
      name: 'Complaint Drafts',
      path: '/search-engines/submission-drafts'
    },
    dashboard: {
      name: 'Dashboard',
      path: '/search-engines/dashboard'
    },
    detections: {
      name: 'Detections',
      path: '/search-engines/detections'
    },
    reporting: {
      name: 'Reporting',
      path: '/search-engines/reporting'
    }
  },
  SOCIAL: {
    basePath: '/social',
    complaintDrafts: {
      name: 'Complaint Drafts',
      path: '/social/submission-drafts'
    },
    dashboard: {
      name: 'Dashboard',
      path: '/social/dashboard'
    },
    detections: {
      name: 'Detections',
      path: '/social/detections'
    },
    reporting: {
      name: 'Reporting',
      path: '/social/reporting'
    }
  }
});
