import { isEqual } from 'lodash';
import { initialize, pageview, set } from 'react-ga';

import { portalConfig } from '../Config/config';
import store from '../Services/Store';
import { browserHistory } from '../Utils/History';
import { areWeTesting } from '../Utils/are-we-testing';
import { initializers } from './initializers';

const googleAnalyticsInitializer = () => {
  initialize('UA-32618270-2', {
    debug: portalConfig.REACT_APP_GA_SHOULD_DEBUG === 'true',
    gaOptions: {
      forceSSL: true,
      testMode: areWeTesting
    }
  });

  pageview(window.location.pathname + window.location.search);
  // Log route changes to Google Analytics
  browserHistory.listen(location => {
    pageview(location.pathname + location.search);
  });

  let user;
  store.subscribe(() => {
    const previousUser = user;
    user = store.getState()?.user;

    if (!isEqual(previousUser, user) && user.isInitialized === true) {
      const user = store.getState().user;
      const currentAccount = store.getState().accounts.currentAccount;

      if (!user || !currentAccount) {
        throw new Error('Unable to send metrics. Missing user or account.');
      }

      set({
        userId: user.id
      });
      set({
        metric1: user.isTracerEmployee ? 'employee' : 'customer'
      });
      set({
        metric2: currentAccount.accountId
      });
      set({
        dimension1: user.id
      });
      set({
        dimension2: user.isTracerEmployee ? 'employee' : 'customer'
      });
      set({
        dimension3: currentAccount.accountId
      });
      set({
        dimension4: currentAccount.accountName
      });
    }
  });
};

initializers.push(googleAnalyticsInitializer);
