import React from 'react';

import logo from '../logos/tracer-combined-horizontal-color.svg';

export class ErrorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  checkForError(error, messageToCheck) {
    if (
      error?.message?.toLowerCase().includes(messageToCheck) ||
      (typeof error === 'string' &&
        error?.toLowerCase().includes(messageToCheck))
    ) {
      this.reloadPage();
    }
  }

  componentDidCatch(error) {
    this.checkForError(error, 'loading chunk');
    this.checkForError(error, `unexpect token '<'`);
  }

  reloadPage() {
    global.location.reload(true);
  }

  render() {
    const errorPage = (
      <div className="ErrorComponent">
        <img
          alt="Tracer Protect Logo"
          className="ErrorComponent-logo"
          src={logo}
        />
        <h1 className="adx-h1 ErrorComponent-message">
          An error occurred. Please{' '}
          <span
            className="link ErrorComponent-messageLink"
            onClick={this.reloadPage}
            role="button"
            tabIndex={0}
          >
            click here
          </span>{' '}
          to refresh your page.
        </h1>
        <h3 className="adx-h3 ErrorComponent-subheading">
          If the problem persists contact your Tracer Protect Account Manager.
        </h3>
      </div>
    );

    return this.state.hasError || !this.props.children
      ? errorPage
      : this.props.children;
  }
}
