import { Auth } from 'aws-amplify';
import axios from 'axios';

import { portalConfig } from '../Config/config';

/**
 * Get the SSO redirect URL
 * @param {String} ssoClientId
 * @returns {Promise} resolving with the redirect URL which points to the SSO login page
 */
export const getSingleSignOnRedirectUrl = ssoClientId => {
  const redirectSignIn = Auth.configure()?.oauth?.redirectSignIn;
  const clientAppId = portalConfig.COGNITO_CLIENT_ID;

  if (!clientAppId) {
    return Promise.reject('clientAppId configuration is required.');
  }
  if (!redirectSignIn) {
    return Promise.reject('redirectSignIn configuration is required.');
  }
  if (!ssoClientId) {
    return Promise.reject('ssoClientId parameter is required.');
  }
  return axios
    .get(
      `${portalConfig.REACT_APP_AUTH_URL}/sso/redirect/${ssoClientId}?redirectUri=${redirectSignIn}&clientAppId=${clientAppId}`
    )
    .then(response => {
      storeSsoSignInPath(window.location.pathname);
      return response.data;
    })
    .then(({ redirectUrl }) => redirectUrl);
};

/**
 * Get the SSO loginUrlPath/ClientId by domain
 * @param {String} domain
 * @returns {Promise} resolves with the SSO login URL path to initiate the SSO login
 */
export const getSingleSignOnClientIdByDomain = domain => {
  if (!domain) {
    return Promise.reject('email domain parameter is required.');
  }
  return axios
    .get(`${portalConfig.REACT_APP_AUTH_URL}/sso/loginUrlPath/${domain}`)
    .then(response => response.data);
};

/**
 * Is this a single sign on user?
 * @returns {Promise} resolves with boolean indicating if the user signed in with SSO
 */
export const isSingleSignOnUser = () =>
  Auth.currentAuthenticatedUser()
    .then(user => user?.storage?.['amplify-signin-with-hostedUI'] === 'true')
    .catch(() => Promise.resolve(false));

/**
 * @type {String}
 */
const USER_NOT_FOUND_KEY = 'adxSsoUserNotFound';

/**
 * Set a user as "not found" in sessionStorage
 * @returns {void}
 */
export const setUserNotFound = () =>
  window.sessionStorage.setItem(USER_NOT_FOUND_KEY, 'true');

/**
 * Check if user is "not found"
 * @returns {Boolean}
 */
export const isUserNotFound = () =>
  window.sessionStorage.getItem(USER_NOT_FOUND_KEY) === 'true';

/**
 * Remove user not found from sessionStorage
 * @returns {void}
 */
export const clearUserNotFound = () =>
  window.sessionStorage.removeItem(USER_NOT_FOUND_KEY);

/**
 * Storage key for SSO Redirect URL
 * @type {String}
 */
const SSO_REDIRECT_URL_KEY = 'adxSsoRedirectUrl';

/**
 * Set SSO redirect URL
 * @param {String} ssoUrl
 * @returns {void}
 */
export const storeSsoRedirectUrl = ssoUrl =>
  window.localStorage.setItem(SSO_REDIRECT_URL_KEY, ssoUrl);

/**
 * Get SSO redirect URL
 * @returns {String|null}
 */
export const getSsoRedirectUrl = () =>
  window.localStorage.getItem(SSO_REDIRECT_URL_KEY);

/**
 * Remove SSO redirect URL from storage
 * @returns {void}
 */
export const clearSsoRedirectUrl = () =>
  window.localStorage.removeItem(SSO_REDIRECT_URL_KEY);

/**
 * Storage key for SSO sign in path
 * @type {String}
 */
const SSO_SIGN_IN_PATH = 'adxSsoSignInPath';

/**
 * Set SSO sign in path
 * @param {String} path
 * @returns {void}
 */
export const storeSsoSignInPath = path =>
  window.localStorage.setItem(SSO_SIGN_IN_PATH, path);

/**
 * Get SSO sign in path
 * @returns {String|null}
 */
export const getStoredSsoSignInPath = () =>
  window.localStorage.getItem(SSO_SIGN_IN_PATH);

/**
 * Remove SSO sign in path from storage
 * @returns {void}
 */
export const clearStoredSsoSignInPath = () =>
  window.localStorage.removeItem(SSO_SIGN_IN_PATH);
