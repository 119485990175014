import has from 'has';

import { storeCurrentAccountId } from '../../Services/Auth2';

const initialState = {
  accounts: [],
  currentAccount: undefined,
  currentClient: undefined,
  selectedAccount: undefined,
  userAccounts: []
};

export const accounts = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACCOUNTS':
      return Object.assign({}, state, {
        accounts: action.payload,
        isLoading: false
      });
    case 'SET_USER_ACCOUNTS':
      return Object.assign({}, state, { userAccounts: action.payload });
    case 'SET_CURRENT_ACCOUNT':
      storeCurrentAccountId(action.payload.accountId);
      return Object.assign({}, state, { currentAccount: action.payload });
    case 'SELECT_ACCOUNT':
      return Object.assign({}, state, { selectedAccount: action.payload });
    case 'SET_SELECTED_ACCOUNT_SUBSCRIPTIONS':
      return Object.assign({}, state, {
        selectedAccountSubscriptions: action.payload
      });
    case 'ADD_ACCOUNT_FORM_ERROR':
      let errors = '';
      if (action.payload) {
        errors = has(action.payload, 'errors')
          ? action.payload.errors
              .map(val => {
                return `${val.field} - ${val.defaultMessage}`;
              })
              .join('<br/>')
          : action.payload.error;
      }
      return Object.assign({}, state, { errors });
    case 'SET_IS_LOADING_ACCOUNTS':
      return Object.assign({}, state, { isLoading: true });
    case 'SET_CURRENT_CLIENT':
      let client = {};
      if (
        action.payload &&
        action.payload._embedded &&
        action.payload._embedded.accountRoles
      ) {
        const filterClient = action.payload._embedded.accountRoles.find(
          role => role.clientId === action.payload._embedded.user.clientId
        );
        if (filterClient) {
          client = filterClient;
        }
      }
      return Object.assign({}, state, { currentClient: client });
    default:
      return state;
  }
};

export const selectCurrentAccount = state => state.accounts.currentAccount;

export const selectCurrentRole = state =>
  state.accounts.currentAccount.role.toUpperCase();

export const selectIsAdmin = state =>
  state.accounts.currentAccount?.role?.toUpperCase()?.includes('ADMIN');

export const selectIsReporter = state =>
  state.accounts.currentAccount?.role?.toUpperCase()?.includes('REPORTER');

export const selectIsNoSettings = state =>
  state.accounts.currentAccount?.role?.toUpperCase()?.includes('NO_SETTINGS');

export const selectIsClientAdmin = state =>
  state.accounts.currentClient?.role?.toUpperCase()?.includes('ADMIN');
