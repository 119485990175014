import React from 'react';
import { Link, Router } from 'react-router-dom';

import { browserHistory } from '../../Utils/History';
import { routes } from '../../routes';
import { Toast as toast } from '../Toast/Toast';

const SEEN_COOKIE_KEY = 'adxSeenCookieNotice';

const setSeenCookieNotice = () =>
  window.localStorage.setItem(SEEN_COOKIE_KEY, 'yes');

const haveNotSeenCookieNotice = () =>
  window.localStorage.getItem(SEEN_COOKIE_KEY) === null;

// Currently only exported for tests
export const CookieNotificationComponent = () => (
  <Router history={browserHistory}>
    <div>
      This site uses cookies to provide a great experience for our users. By
      using Tracer Protect you agree to our{' '}
      <Link to={routes.COOKIE_POLICY.path}>{routes.COOKIE_POLICY.name}</Link>.
    </div>
  </Router>
);

export const CookieToast = {
  launch: () => {
    if (
      haveNotSeenCookieNotice() &&
      !window.location.pathname.startsWith('/sso')
    ) {
      toast.info(<CookieNotificationComponent />, {
        autoClose: false,
        onClose: setSeenCookieNotice,
        // Prevents duplicates of this toast
        toastId: 'COOKIE_TOAST'
      });
    }
  }
};
