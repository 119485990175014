import { Toast as toast } from '../Shared/Toast/Toast';
import { initializers } from './initializers';

const ACCOUNT_SWITCHED_KEY = 'accountSwitchedTo';

export const setAccountSwitchedTo = accountName =>
  sessionStorage.setItem(ACCOUNT_SWITCHED_KEY, accountName);

// Notify when account has been switched
const accountSwitchedInitializer = () => {
  const newAccount = sessionStorage.getItem(ACCOUNT_SWITCHED_KEY);
  if (newAccount) {
    setTimeout(() => {
      toast.success(`Switched to ${newAccount} account`);
      sessionStorage.removeItem(ACCOUNT_SWITCHED_KEY);
    }, 333);
  }
};

initializers.push(accountSwitchedInitializer);
