// Extracting this functionality into a module to avoid loading jsdom during tests
export const assignAuthToWindowForCypress = (
  Auth,
  getCognitoJwtToken,
  exchangeToken,
  signOut
) => {
  // Used for logging in with Cypress integration tests
  window.AmplifyAuth = Auth;
  window.AdxAuth_getCognitoJwtToken = getCognitoJwtToken;
  window.AdxAuth_exchangeToken = exchangeToken;
  window.AdxAuth_signOut = signOut;
};
