/* eslint-disable import/no-default-export */
import findIndex from 'lodash/findIndex';
import moment from 'moment';

const initialState = {
  accountOfferingCodes: [],
  addLabelResponse: undefined,
  caseEvidenceTableData: undefined,
  data: undefined,
  dcError: undefined,
  evidenceRequests: {
    detection: undefined,
    email: undefined,
    submission: undefined
  },
  fetchData: false,
  labels: undefined,
  logs: [],
  screenshots: {},
  submissions: [],
  whitelist: { offering: false, publisher: false }
};

function detailsCardData(state = initialState, action) {
  switch (action.type) {
    case 'SET_CURRENT_DATA':
      return { ...state, data: action.payload };
    case 'ADD_LABEL_RESPONSE':
      return { ...state, addLabelResponse: action.payload };
    case 'DC_ERROR':
      return { ...state, dcError: action.payload };
    case 'SET_FETCH_DATA':
      return { ...state, fetchData: action.payload };
    case 'SET_ACCOUNT_OFFERING_CODES':
      return { ...state, accountOfferingCodes: action.payload };
    case 'SET_SUBMISSIONS':
      return {
        ...state,
        submissions: action.payload.sort((submission1, submission2) => {
          if (submission1.createdOn < submission2.createdOn) {
            return 1;
          } else if (submission1.createdOn > submission2.createdOn) {
            return -1;
          } else {
            return 0;
          }
        })
      };
    case 'SET_SUBMISSION_EMAILS':
      const index = findIndex(state.submissions, [
        'submissionId',
        action.payload.submission.submissionId
      ]);
      state.submissions.splice(index, 1, {
        ...action.payload.submission,
        emails: action.payload.emails._embedded
          ? action.payload.emails._embedded.emails
          : []
      });
      return { ...state };
    case 'SET_SUBMISSION_LOGS':
      const logs = action.payload.logs.map(log => {
        const returnLogs = log.data._embedded['submission-logs'];
        return returnLogs.map(rtnLog => {
          try {
            rtnLog.data = JSON.parse(rtnLog.data);
          } catch (e) {
            console.error(e);
          }
          return rtnLog;
        });
      });
      return { ...state, ...{ logs } };
    case 'SET_OFFERING_WHITELIST':
      return {
        ...state,
        whitelist: { ...state.whitelist, offering: action.payload }
      };
    case 'SET_PUBLISHER_WHITELIST':
      return {
        ...state,
        whitelist: { ...state.whitelist, publisher: action.payload }
      };
    case 'EVIDENCE_REQUESTS':
      return {
        ...state,
        evidenceRequests: {
          ...state.evidenceRequests,
          detection: action.payload['DETECTION_EMAIL'],
          emails: action.payload['EMAIL'],
          submission: action.payload['SUBMISSION_EMAIL']
        }
      };
    case 'SET_SCREENSHOTS':
      return {
        ...state,
        screenshots: action.payload
      };
    case 'SET_UPLOADED_SCREENSHOT_URL':
      return {
        ...state,
        uploadedScreenshotUrl: action.payload
      };

    case 'SET_CASE_EVIDENCE':
      const addTypeToCaseEvidence = (caseEvidenceArray, typeToAdd) =>
        caseEvidenceArray?.map(evidenceItem => {
          evidenceItem.createdOn = new moment(evidenceItem.createdOn).format(
            'MMM DD, YYYY hh:mm a'
          );
          evidenceItem.created = evidenceItem.createdOn;
          evidenceItem.download =
            evidenceItem.status === 'COMPLETE'
              ? evidenceItem._links['ep-download'].href
              : '';
          evidenceItem.type = typeToAdd;
          return evidenceItem;
        });
      const detectionTypeOfCaseEvidence =
        addTypeToCaseEvidence(action.payload?.DETECTION, 'Detection') || [];

      const emailTypeOfCaseEvidence =
        addTypeToCaseEvidence(action.payload?.DETECTION_EMAIL, 'Email') || [];
      const submissionTypeOfCaseEvidence =
        addTypeToCaseEvidence(
          action.payload?.SUBMISSION_EMAIL,
          'Submission Email'
        ) || [];

      const sortedCaseEvidenceWithTypes = detectionTypeOfCaseEvidence
        .concat(emailTypeOfCaseEvidence, submissionTypeOfCaseEvidence)
        .sort(
          (val1, val2) =>
            new Date(val2.createdOn).getTime() -
            new Date(val1.createdOn).getTime()
        );
      return {
        ...state,
        caseEvidenceTableData: sortedCaseEvidenceWithTypes
      };
    case 'SET_DUPLICATE_DETECTIONS':
      return {
        ...state,
        duplicateDetections: action.payload
      };
    default:
      return state;
  }
}

export default detailsCardData;
