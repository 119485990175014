const initialState = {
  isLoading: false,
  paging: {},
  results: []
};

const drafts = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_DRAFTS':
      return Object.assign({}, state, {
        isLoading: false,
        links: action.payload._links,
        paging: action.payload.page,
        results: action.payload
      });
    case 'SET_IS_LOADING_DRAFTS':
      return Object.assign({}, state, { isLoading: true });
    default:
      return state;
  }
};
export default drafts;
