import hasProp from 'has';

import store from '../../Services/Store';

export const addPermissions = permissions => {
  return {
    payload: permissions,
    type: 'ADD_PERMISSIONS'
  };
};

export const addCasePermissions = permissions => {
  return {
    payload: permissions,
    type: 'ADD_CASE_PERMISSIONS'
  };
};

export const setPermissions = permissions => {
  return {
    payload: permissions,
    type: 'SET_PERMISSIONS'
  };
};

export const hasPermission = (permission, type) => {
  const checkPermission = () => {
    if (type === undefined) {
      return hasProp(store.getState().permissions, permission);
    } else if (store.getState().permissions[type]) {
      return hasProp(store.getState().permissions[type], permission);
    } else {
      return false;
    }
  };
  if (store.getState().permissions.retrieved) {
    return checkPermission();
  } else {
    throw new Error('Unable to check permissions.');
  }
};
