/* eslint-disable import/no-default-export */
import axios from 'axios';
import { isUndefined } from 'lodash';

import { portalConfig } from '../Config/config';
import { Subscriptions } from '../Shared/Admin/Subscriptions/Subscriptions';
import { Toast as toast } from '../Shared/Toast/Toast';
import { setFormErrors } from '../store/adminUsers/adminUsers.actions';
import { userFormError } from '../store/user/user.actions';
import { renewUserAndToken } from './Auth2';
import { getDecodedToken } from './BearerToken';
import store from './Store';

export default class AppdetexAuth {
  static addUserToAccounts(accounts) {
    const promises = accounts.map(account =>
      axios.post(account._links.modify.href, { role: account.newRole })
    );
    return axios.all(promises).then(() => renewUserAndToken());
  }

  static addUserToClients(clients) {
    const promises = clients.map(client =>
      axios.post(client._links.modify.href, { role: client.newRole })
    );
    return axios.all(promises).then(() => renewUserAndToken());
  }

  static createUser(payload) {
    store.dispatch(setFormErrors(undefined));
    for (const key in payload) {
      if (payload[key] === '') {
        delete payload[key];
      }
    }
    return axios
      .post(`${portalConfig.REACT_APP_AUTH_URL}/users`, payload)
      .then(res => {
        return AppdetexAuth.getUsers().then(() => {
          return res;
        });
      })
      .catch(err => {
        store.dispatch(setFormErrors(err.response.data));
        throw err;
      });
  }

  static deleteUser(url) {
    return axios
      .delete(url)
      .then(res => {
        toast.success('Successfully Deleted User');
        return res;
      })
      .catch(err => {
        toast.error('Failed To Delete User');
        throw err;
      });
  }

  static editClient(url, client, isActive) {
    if (!isUndefined(isActive) || !client.status) {
      client.status = !isActive ? 'INACTIVE' : 'ACTIVE';
    }

    const createLink = client._links.createSubscription.href;
    return axios
      .put(url, client)
      .then(() => {
        // Refresh our token to make sure we have the latest subscription information, if we edited the client owning our active account
        if (AppdetexAuth.getCurrentAccount().clientId === client.id) {
          renewUserAndToken();
        }

        if (getDecodedToken()?.isSelfManaged) {
          Subscriptions.changeSubscriptions(client.subscriptions, createLink);
        }
      })
      .catch(err => {
        // Still try to modify subscriptions if the edit failed
        if (getDecodedToken()?.isSelfManaged) {
          Subscriptions.changeSubscriptions(client.subscriptions, createLink);
        }
        if (err) {
          throw err;
        }
      });
  }

  static editSpecifiedUser(user) {
    const userEditPromise = axios.put(user._links.self.href, user);

    // Link might not exist if we aren't logged in as a super admin
    const createSubscriptionLink = user._links.createSubscription?.href;
    const subscriptionPromise =
      createSubscriptionLink && user.subscriptions
        ? Subscriptions.changeSubscriptions(
            Subscriptions.parseSubscriptionsFromForm(user.subscriptions),
            createSubscriptionLink
          )
        : Promise.resolve();

    return Promise.all([userEditPromise, subscriptionPromise]).then(res => {
      // Refresh our token to make sure we have the latest subscription information, if we edited our own user
      if (AppdetexAuth.getUserObj().id === user.id) {
        renewUserAndToken();
      }
      return res[0];
    });
  }

  static editUser(payload) {
    const updatedUser = Object.assign(AppdetexAuth.getUserObj(), payload);
    for (const key in updatedUser) {
      if (updatedUser[key] === '') {
        delete updatedUser[key];
      }
    }
    return axios
      .put(
        `${portalConfig.REACT_APP_AUTH_URL}/users/${updatedUser.id}`,
        updatedUser
      )
      .then(() => {
        store.dispatch(userFormError(null));
        toast.success('User has been successfully updated');
        return renewUserAndToken();
      })
      .catch(err => {
        const errorObject = err?.response?.data?.errors;
        if (errorObject?.field === 'phone' && errorObject?.defaultMessage) {
          toast.error(errorObject.defaultMessage, { autoClose: false });
        } else {
          toast.error('Failed to update user');
        }
        if (err) {
          return store.dispatch(userFormError(err.response.data));
        }
      });
  }

  static getAccount(accountId) {
    return axios.get(
      `${portalConfig.REACT_APP_AUTH_URL}/accounts/${accountId}`
    );
  }

  static getAccounts(url, searchString) {
    let requestUrl = url
      ? url
      : `${portalConfig.REACT_APP_AUTH_URL}/accounts/clients/${
          AppdetexAuth.getUserObj().clientId
        }`;
    if (searchString) {
      requestUrl = url
        ? `${requestUrl}&search=${searchString}`
        : `${requestUrl}?search=${searchString}`;
    }
    return axios.get(requestUrl).then(res => {
      return res;
    });
  }

  static getClient(id) {
    return axios
      .get(`${portalConfig.REACT_APP_AUTH_URL}/clients/${id}`)
      .then(res => {
        return res;
      });
  }

  static getClients(url, searchString) {
    let requestUrl = url ? url : `${portalConfig.REACT_APP_AUTH_URL}/clients`;
    if (searchString) {
      requestUrl = `${requestUrl}?search=${searchString}`;
    }
    return axios.get(requestUrl).then(res => {
      return res;
    });
  }

  /**
   * Do not use. Get the data from Redux directly instead.
   * @deprecated
   * @returns {Object|null}
   */
  static getCurrentAccount() {
    return store.getState().accounts
      ? store.getState().accounts.currentAccount
      : null;
  }

  static getSpecifiedUser(url) {
    return axios.get(url);
  }

  static getUserClients(id) {
    return axios.get(`${portalConfig.REACT_APP_AUTH_URL}/users/${id}/clients`);
  }

  /**
   * Do not use. Get the data from Redux directly instead.
   * @deprecated
   * @returns {Object|null}
   */
  static getUserObj() {
    return store.getState().user || null;
  }

  static getUsers(url, searchString) {
    let requestUrl = url
      ? url
      : `${portalConfig.REACT_APP_AUTH_URL}/users/clients/${
          AppdetexAuth.getCurrentAccount().clientId
        }`;
    if (searchString) {
      requestUrl = `${requestUrl}&search=${searchString}`;
    }
    return axios.get(requestUrl).then(res => {
      return res;
    });
  }

  static removeUserFromAccount(url) {
    return axios.delete(url);
  }

  static removeUserFromAccounts(accounts) {
    const promises = accounts.map(account =>
      axios.delete(account._links.modify.href)
    );
    return axios.all(promises).then(() => renewUserAndToken());
  }

  static removeUserFromClient(url) {
    return axios.delete(url);
  }

  static removeUserFromClients(clients) {
    const promises = clients.map(client =>
      axios.delete(client._links.modify.href)
    );
    return axios.all(promises).then(() => renewUserAndToken());
  }

  static saveClient(payload) {
    payload.adminClient = false;
    payload.status = 'ACTIVE';
    return axios
      .post(`${portalConfig.REACT_APP_AUTH_URL}/clients`, payload)
      .then(res =>
        Subscriptions.changeSubscriptions(
          payload.subscriptions,
          res.data._links.createSubscription.href
        )
      )
      .catch(err => {
        if (err) {
          throw err;
        }
      });
  }
}
