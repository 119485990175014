import * as info from '../../../Services/Info';

const initialState = {
  basicHeaders: {
    apps: [],
    domains: [],
    marketplaces: [],
    searchEngines: [],
    social: []
  },
  basicLinks: {
    apps: [],
    domains: [],
    marketplaces: [],
    searchEngines: [],
    social: []
  },
  basicPaging: {
    apps: {},
    domains: {},
    marketplaces: {},
    searchEngines: {},
    social: {}
  },
  basicResults: {
    apps: [],
    domains: [],
    marketplaces: [],
    searchEngines: [],
    social: []
  },
  headers: [],
  isLoading: false,
  paging: {},
  results: [],
  searchLinks: [],
  selectedApp: null,
  selectedAppLabels: { labels: [] }
};

export const searchResults = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'SEARCH_RESULTS':
      let results, headers;
      if (payload._embedded?.searchResults) {
        results = payload._embedded.searchResults;
      } else if (payload.searchHits) {
        results = payload.searchHits;
      } else if (payload.offerings) {
        // Adv search url case
        results = payload.offerings;
      } else {
        results = payload;
      }
      if (payload?._embedded?.displayFields) {
        headers = payload._embedded.displayFields;
      } else if (payload?.displayFields) {
        // Adv search url case
        headers = payload?.displayFields;
      } else {
        headers = [];
      }
      return Object.assign({}, state, {
        headers,
        paging: payload.page || info.paging,
        results,
        searchLinks: payload._links || {}
      });
    case 'SEARCH_RESULTS_CLEAR':
      return Object.assign({}, state, {
        paging: [],
        results: [],
        searchLinks: []
      });
    case 'SEARCH_RESULTS_SELECT_APP':
      return Object.assign({}, state, {
        selectedApp: payload._embedded.offering
      });
    case 'SELECTED_APP_LINKS':
      return Object.assign({}, state, {
        selectedAppLinks: payload
      });
    case 'SEARCH_RESULTS_SELECT_APP_LABELS':
      return Object.assign({}, state, {
        selectedAppLabels: payload
      });
    case 'SEARCH_RESULTS_SELECT_APP_CLEAR_LABELS':
      return Object.assign({}, state, {
        selectedAppLabels: { labels: [] }
      });
    case 'SEARCH_RESULTS_SELECT_APP_CLEAR':
      return Object.assign({}, state, { selectedApp: null });
    case 'ADD_LABEL_RESPONSE':
      return Object.assign({}, state, {
        addLabelResponse: payload
      });
    case 'SEARCH_RESULTS_SET_LOADING':
      return Object.assign({}, state, { isLoading: payload });
    case 'SET_BASIC_RESULTS':
      return Object.assign({}, state, {
        basicHeaders: {
          apps:
            payload._embedded && payload._embedded.apps
              ? payload._embedded.apps._embedded.displayFields
              : [],
          domains:
            payload._embedded && payload._embedded.domains
              ? payload._embedded.domains._embedded.displayFields
              : [],
          marketplaces:
            payload._embedded && payload._embedded.marketplaces
              ? payload._embedded.marketplaces._embedded.displayFields
              : [],
          searchEngines:
            payload._embedded && payload._embedded.searchEngines
              ? payload._embedded.searchEngines._embedded.displayFields
              : [],
          social:
            payload._embedded && payload._embedded.social
              ? payload._embedded.social._embedded.displayFields
              : []
        },
        basicLinks: {
          apps:
            payload._embedded && payload._embedded.apps
              ? payload._embedded.apps._links
              : [],
          domains:
            payload._embedded && payload._embedded.domains
              ? payload._embedded.domains._links
              : [],
          marketplaces:
            payload._embedded && payload._embedded.marketplaces
              ? payload._embedded.marketplaces._links
              : [],
          searchEngines:
            payload._embedded && payload._embedded.searchEngines
              ? payload._embedded.searchEngines._links
              : [],
          social:
            payload._embedded && payload._embedded.social
              ? payload._embedded.social._links
              : []
        },
        basicPaging: {
          apps:
            payload._embedded && payload._embedded.apps
              ? payload._embedded.apps.page
              : {},
          domains:
            payload._embedded && payload._embedded.domains
              ? payload._embedded.domains.page
              : {},
          marketplaces:
            payload._embedded && payload._embedded.marketplaces
              ? payload._embedded.marketplaces.page
              : {},
          searchEngines:
            payload._embedded && payload._embedded.searchEngines
              ? payload._embedded.searchEngines.page
              : {},
          social:
            payload._embedded && payload._embedded.social
              ? payload._embedded.social.page
              : {}
        },
        basicResults: {
          apps:
            payload._embedded && payload._embedded.apps
              ? payload._embedded.apps._embedded.searchResults
              : [],
          domains:
            payload._embedded && payload._embedded.domains
              ? payload._embedded.domains._embedded.searchResults
              : [],
          marketplaces:
            payload._embedded && payload._embedded.marketplaces
              ? payload._embedded.marketplaces._embedded.searchResults
              : [],
          searchEngines:
            payload._embedded && payload._embedded.searchEngines
              ? payload._embedded.searchEngines._embedded.searchResults
              : [],
          social:
            payload._embedded && payload._embedded.social
              ? payload._embedded.social._embedded.searchResults
              : []
        }
      });
    case 'SET_APPS_BASIC_RESULTS':
      return Object.assign({}, state, {
        basicHeaders: {
          ...state.basicHeaders,
          apps:
            payload._embedded && payload._embedded.apps
              ? payload._embedded.apps._embedded.displayFields
              : []
        },
        basicLinks: {
          ...state.basicLinks,
          apps:
            payload._embedded && payload._embedded.apps
              ? payload._embedded.apps._links
              : []
        },
        basicPaging: {
          ...state.basicPaging,
          apps:
            payload._embedded && payload._embedded.apps
              ? payload._embedded.apps.page
              : {}
        },
        basicResults: {
          ...state.basicResults,
          apps:
            payload._embedded && payload._embedded.apps
              ? payload._embedded.apps._embedded.searchResults
              : []
        }
      });
    case 'SET_DOMAINS_BASIC_RESULTS':
      return Object.assign({}, state, {
        basicHeaders: {
          ...state.basicHeaders,
          domains:
            payload._embedded && payload._embedded.domains
              ? payload._embedded.domains._embedded.displayFields
              : []
        },
        basicLinks: {
          ...state.basicLinks,
          domains:
            payload._embedded && payload._embedded.domains
              ? payload._embedded.domains._links
              : []
        },
        basicPaging: {
          ...state.basicPaging,
          domains:
            payload._embedded && payload._embedded.domains
              ? payload._embedded.domains.page
              : {}
        },
        basicResults: {
          ...state.basicResults,
          domains:
            payload._embedded && payload._embedded.domains
              ? payload._embedded.domains._embedded.searchResults
              : []
        }
      });
    case 'SET_SEARCH_ENGINES_BASIC_RESULTS':
      return Object.assign({}, state, {
        basicHeaders: {
          ...state.basicHeaders,
          searchEngines:
            payload._embedded && payload._embedded.searchEngines
              ? payload._embedded.searchEngines._embedded.displayFields
              : []
        },
        basicLinks: {
          ...state.basicLinks,
          searchEngines:
            payload._embedded && payload._embedded.searchEngines
              ? payload._embedded.searchEngines._links
              : []
        },
        basicPaging: {
          ...state.basicPaging,
          searchEngines:
            payload._embedded && payload._embedded.searchEngines
              ? payload._embedded.searchEngines.page
              : {}
        },
        basicResults: {
          ...state.basicResults,
          searchEngines:
            payload._embedded && payload._embedded.searchEngines
              ? payload._embedded.searchEngines._embedded.searchResults
              : []
        }
      });
    case 'SET_SOCIAL_BASIC_RESULTS':
      return Object.assign({}, state, {
        basicHeaders: {
          ...state.basicHeaders,
          social:
            payload._embedded && payload._embedded.social
              ? payload._embedded.social._embedded.displayFields
              : []
        },
        basicLinks: {
          ...state.basicLinks,
          social:
            payload._embedded && payload._embedded.social
              ? payload._embedded.social._links
              : []
        },
        basicPaging: {
          ...state.basicPaging,
          social:
            payload._embedded && payload._embedded.social
              ? payload._embedded.social.page
              : {}
        },
        basicResults: {
          ...state.basicResults,
          social:
            payload._embedded && payload._embedded.social
              ? payload._embedded.social._embedded.searchResults
              : []
        }
      });
    case 'SET_MARKETPLACES_BASIC_RESULTS':
      return Object.assign({}, state, {
        basicHeaders: {
          ...state.basicHeaders,
          marketplaces:
            payload._embedded && payload._embedded.marketplaces
              ? payload._embedded.marketplaces._embedded.displayFields
              : []
        },
        basicLinks: {
          ...state.basicLinks,
          marketplaces:
            payload._embedded && payload._embedded.marketplaces
              ? payload._embedded.marketplaces._links
              : []
        },
        basicPaging: {
          ...state.basicPaging,
          marketplaces:
            payload._embedded && payload._embedded.marketplaces
              ? payload._embedded.marketplaces.page
              : {}
        },
        basicResults: {
          ...state.basicResults,
          marketplaces:
            payload._embedded && payload._embedded.marketplaces
              ? payload._embedded.marketplaces._embedded.searchResults
              : []
        }
      });
    case 'BASIC_RESULTS_CLEAR':
      return Object.assign({}, state, {
        basicLinks: { apps: [], domains: [], marketplaces: [], social: [] },
        basicPaging: { apps: {}, domains: {}, marketplaces: {}, social: {} },
        basicResults: { apps: [], domains: [], marketplaces: [], social: [] }
      });
    default:
      return state;
  }
};

export const selectIsSearchResultsLoading = state =>
  state.app.searchResults.isLoading;
