import moment from 'moment';

import { normalizeCamelCase } from '../../Utils/StringNormalize';

const jobsState = {
  bulkUpdateJobs: [],
  bulkUpdateJobsViewType: null,
  isLoadingBulkUpdateJobs: false,
  jobProgressValue: null,
  totalJobsInQueue: 0
};

const parseJobData = jobData => {
  const parsedJobData = JSON.parse(jobData);
  let fieldChanges = {};
  if (parsedJobData.fieldChanges) {
    /* eslint-disable no-unused-vars */
    fieldChanges = Object.fromEntries(
      Object.entries(parsedJobData.fieldChanges).filter(
        ([_, value]) => value != null
      )
    );
  }
  if (parsedJobData.labels?.length) {
    const labels = parsedJobData.labels.map(labelObject => labelObject.label);
    fieldChanges.labels = {
      add: parsedJobData.labelAction === 'ADD' ? labels : [],
      remove: parsedJobData.labelAction === 'REMOVE' ? labels : []
    };
  }
  if (parsedJobData.brands) {
    fieldChanges.brands = parsedJobData.brands;
  }
  if (parsedJobData.comment) {
    fieldChanges.comment = parsedJobData.comment;
  }
  if (parsedJobData.fieldDeletions) {
    parsedJobData.fieldDeletions.forEach(field => {
      // -1 denotes a field being deleted
      fieldChanges[field] = -1;
    });
  }
  return fieldChanges;
};

const parseTooltipData = (data, customFields) => {
  const getCustomFieldDisplayText = customFieldText => {
    if (customFieldText.toLowerCase().includes('custom')) {
      return (
        customFields?.data?.find(
          field => field.indexColumnName === customFieldText
        )?.['displayName'] || customFieldText
      );
    } else {
      return normalizeCamelCase(customFieldText);
    }
  };

  const getValue = (currentValue, key) => {
    const valueIsADate = () =>
      typeof currentValue === 'number' && new Date(currentValue).getTime() > 0;

    if (currentValue === -1) {
      return 'Value was removed';
    } else if (typeof currentValue === 'object') {
      // If brands were updated we get an object with two entries, an `add` array of brands that were added
      // and a `remove` array of brands that were removed
      if (key === 'brands') {
        currentValue['Adding'] = currentValue['add']?.join(', ');
        delete currentValue['add'];
        currentValue['Removing'] = currentValue['remove']?.join(', ');
        delete currentValue['remove'];
      } else {
        const addingValue = [];
        const removingValue = [];
        currentValue.forEach((changeObject, index) => {
          if (changeObject.changeAction === 'ADD') {
            addingValue.push(changeObject.item);
          }
          if (changeObject.changeAction === 'REMOVE') {
            removingValue.push(changeObject.item);
          }
          delete currentValue[index];
        });
        currentValue['Adding'] = addingValue.join(', ');
        currentValue['Removing'] = removingValue.join(', ');
      }

      return currentValue;
    } else if (valueIsADate()) {
      return moment(currentValue).format('L');
    } else {
      return currentValue;
    }
  };

  return Object.keys(data).reduce((accumulator, value) => {
    return {
      ...accumulator,
      [getCustomFieldDisplayText(value)]: getValue(data[value], value)
    };
  }, {});
};

const jobs = (state = jobsState, action) => {
  switch (action.type) {
    case 'SET_BULK_UPDATE_JOBS':
      return Object.assign({}, state, {
        bulkUpdateJobs: action.payload
          .sort((job1, job2) => job2.created - job1.created)
          .map(job => {
            const successCount =
              job.progress?.find(progress => progress.status === 'SUCCEEDED')
                ?.kount ?? 0;
            const failureCount =
              job.progress?.find(progress => progress.status === 'FAILED')
                ?.kount ?? 0;
            const parsedFieldChanges = parseJobData(job.data);
            return {
              ...job,
              completedCount: Number(
                successCount + failureCount
              ).toLocaleString(),
              failureCount: failureCount.toLocaleString(),
              htmlTooltipData: parseTooltipData(
                parsedFieldChanges,
                action.customFields
              ),
              parsedFieldChanges: parsedFieldChanges,
              successCount: successCount.toLocaleString(),
              totalCount: job.progress
                ?.map(progress => progress.kount)
                .reduce((partialSum, a) => partialSum + a, 0)
                .toLocaleString()
            };
          })
      });
    case 'SET_IS_LOADING_BULK_UPDATE_JOBS':
      return Object.assign({}, state, {
        isLoadingBulkUpdateJobs: action.payload
      });
    case 'SET_TOTAL_JOBS_IN_QUEUE':
      return Object.assign({}, state, {
        totalJobsInQueue: action.payload
      });

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default jobs;

export const selectTotalJobsInQueue = state => state.jobs.totalJobsInQueue;

export const selectBulkUpdateJobs = state => state.jobs.bulkUpdateJobs;
export const selectIsLoadingBulkUpdateJobs = state =>
  state.jobs.isLoadingBulkUpdateJobs;
