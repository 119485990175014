import React from 'react';

import ComponentLoading from '../Shared/ComponentLoading/ComponentLoading';

export const Loading = props => {
  if (
    props.error &&
    props.error.message &&
    props.error.message.includes('Loading chunk')
  ) {
    return global.location.reload(true);
  } else if (props.error) {
    return <div className="alert alert-danger">Failed to load Component</div>;
  } else {
    return <ComponentLoading />;
  }
};
