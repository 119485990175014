const initialState = {
  accountBrands: [],
  isLoading: false
};
export const brands = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_ACCOUNT_BRANDS':
      return Object.assign({}, state, {
        accountBrands: action.payload._embedded?.brands,
        links: action.payload._links,
        paging: action.payload.page
      });
    case 'SET_IS_LOADING_BRANDS':
      return Object.assign({}, state, {
        isLoading: action.payload
      });
    default:
      return state;
  }
};
