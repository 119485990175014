import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { portalConfig } from '../Config/config';
import AppdetexAuth from '../Services/Auth';
import { initializers } from './initializers';

const bugsnagInitializer = () => {
  Bugsnag.start({
    apiKey: '061aa4356edc3712b7ff56dced885d64',
    enabledReleaseStages: ['production'],
    // appVersion: process.env.REACT_APP_VERSION,
    onError: event => {
      event.addMetadata('user', AppdetexAuth.getUserObj());
      event.addMetadata('account', AppdetexAuth.getCurrentAccount());
    },
    plugins: [new BugsnagPluginReact()],
    releaseStage: portalConfig.REACT_APP_HOST_ENV
  });
};

initializers.push(bugsnagInitializer);
