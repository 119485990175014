const initialState = {
  accessKeys: {}
};

const apiManagement = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACCESS_KEYS':
      let accessKeys = {
        data: []
      };
      if (action.payload._embedded) {
        accessKeys = {
          data: action.payload._embedded.emails,
          links: action.payload._links,
          paging: action.payload.page
        };
      } else if (action.payload.length) {
        accessKeys = {
          data: action.payload
        };
      }
      return Object.assign({}, state, {
        accessKeys: accessKeys,
        isLoading: false
      });
    default:
      return state;
  }
};

export default apiManagement;
