const initialState = {
  allViews: [],
  isLoading: false,
  paging: {},
  views: []
};

const savedViews = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SAVED_VIEWS':
      return Object.assign({}, state, {
        isLoading: false,
        links: action.payload._links,
        paging: action.payload.page,
        views: action.payload
      });
    case 'SET_CURRENT_VIEW':
      return Object.assign({}, state, {
        currentView: action.payload
      });
    case 'SET_ALL_SAVED_VIEWS':
      return Object.assign({}, state, {
        allViews: action.payload,
        isLoading: false
      });
    case 'SET_PINNED_VIEWS':
      return Object.assign({}, state, {
        isLoading: false,
        pinnedViews: action.payload
      });
    case 'SET_IS_LOADING_SAVED_VIEWS':
      return Object.assign({}, state, { isLoading: true });
    case 'SET_USER_DEFAULT_VIEWS':
      return Object.assign({}, state, {
        defaultViews: action.payload
      });
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default savedViews;
