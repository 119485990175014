import { Toast as toast } from '../Shared/Toast/Toast';
import {
  getPortalProductByName,
  getPortalProductBySubscriptionType
} from '../Utils/PortalProducts';
import { getDomainsAndWebsitesTabText } from '../Utils/getDomainsAndWebsitesTabText';
import {
  setCurrentClientSubscription,
  setCurrentClientSubscriptions,
  setDomainsAndWebsitesTabText
} from '../store/adminClients/adminClients.actions';
import { getDecodedToken } from './BearerToken';
import store from './Store';
import { getStoredMostRecentModuleName } from './User';

/**
 * @typedef Subscription
 * @type {Object}
 * @property {Boolean} enf
 * @property {Number} mss
 * @property {String} type
 */

/**
 * @throws
 */
const displayErrorToUserAndSignOut = () => {
  toast.error(
    'Subscriptions could not be found for this account or user. If the issue persists, contact the account manager to set up subscriptions.',
    { autoClose: false, toastId: 'NO_SUBSCRIPTIONS' }
  );

  throw new Error('Could not find subscriptions.');
};

/**
 * Try to find most recently used subscription in current client subscriptions.
 * @returns {Subscription} subscription
 */
const getMostRecentlyUsedSubscription = () => {
  const mostRecentModuleName = getStoredMostRecentModuleName();
  if (mostRecentModuleName) {
    const subscriptionType =
      getPortalProductByName(mostRecentModuleName)?.subscriptionType;

    return store
      .getState()
      .adminClients.currentClientSubscriptions.find(
        subscription => subscription.type === subscriptionType
      );
  }
};

/**
 * Find the first subscription which maps to a portal module
 * @returns {Subscription} subscription
 */
const getFirstSubscription = () => {
  const subscriptionsForModules = store
    .getState()
    .adminClients.currentClientSubscriptions.filter(
      subscription =>
        getPortalProductBySubscriptionType(subscription.type) !== undefined
    );
  if (subscriptionsForModules.length === 0) {
    displayErrorToUserAndSignOut();
  } else {
    return subscriptionsForModules[0];
  }
};

/**
 * Decode and parse the Appdetex JWT and return the user subscriptions
 * @returns {Subscription[]} subscriptions
 */
const getSubscriptionsFromJwt = () => {
  let subscriptions;
  try {
    subscriptions = getDecodedToken().subs;
  } catch (e) {
    window.console.error('Subscriptions#getSubscriptionsFromJwt', e);
    subscriptions = null;
  }

  return subscriptions;
};

/**
 * Set the user subscriptions in redux. Sign them out if they have none.
 */
export const setSubscriptions = () => {
  const subscriptions = getSubscriptionsFromJwt();

  if (subscriptions === null) {
    return displayErrorToUserAndSignOut();
  } else {
    store.dispatch(
      setDomainsAndWebsitesTabText(getDomainsAndWebsitesTabText(subscriptions))
    );
    return store.dispatch(setCurrentClientSubscriptions(subscriptions));
  }
};

/**
 * Set the most recently used subscription/module in redux. Sign them out
 * if they have no subscriptions which map to a module.
 */
export const setCurrentSubscriptionOnAppLoad = () =>
  store.dispatch(
    setCurrentClientSubscription(
      getMostRecentlyUsedSubscription() ?? getFirstSubscription()
    )
  );
