/* eslint-disable import/no-default-export */
const initialState = {
  customFields: {},
  defaultTableData: [],
  filterValues: {},
  isLoading: false
};

const customization = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CUSTOM_FIELDS':
      let customFields = {
        data: []
      };
      if (action.payload?.customFields?.length) {
        customFields = {
          data: action.payload.customFields
        };
      }
      return Object.assign({}, state, {
        currentModule: action.payload.module,
        customFields: customFields,
        isLoading: false
      });
    case 'SET_VALUES_TABLE_DATA':
      return Object.assign({}, state, {
        filterValues: {
          ...state.filterValues,
          [action.payload.name]: {
            _links: action.payload._links,
            items: action.payload._embedded
              ? action.payload._embedded.filters
              : [],
            name: action.payload.name,
            page: action.payload.page,
            totalElements: action.payload.page.totalElements,
            values: action.payload._embedded
              ? action.payload._embedded.filters
              : []
          }
        },
        isLoading: false
      });
    case 'SET_DEFAULT_TABLE_DATA':
      return Object.assign({}, state, {
        defaultTableData: action.payload,
        isLoading: false
      });
    case 'SET_SELECTED_CUSTOM_FIELD':
      return Object.assign({}, state, {
        selectedCustomField: action.payload ? action.payload : null
      });
    case 'SET_IS_LOADING_CUSTOMIZATION':
      return Object.assign({}, state, { isLoading: true });
    case 'SET_FORM_ERRORS':
      const errorObj = {};
      if (action.payload?.errors?.length)
        action.payload.errors.map(error => {
          return (errorObj[error.field] =
            error.defaultMessage.charAt(0).toUpperCase() +
            error.defaultMessage.substring(1));
        });
      if (!Object.keys(errorObj).length) {
        let errorString;
        if (action.payload) {
          errorString = action.payload.errors?.length
            ? action.payload.errors
                .map(error => {
                  return `${error.field} - ${error.defaultMessage}`;
                })
                .join('<br />')
            : action.payload.message;
        }
        return Object.assign({}, state, { formErrors: errorString });
      }
      return Object.assign({}, state, { formErrors: errorObj });
    case 'SET_MODULE_BASED_SUB_TABS':
      return Object.assign({}, state, {
        moduleBasedSubTabs: action.payload
      });
    default:
      return state;
  }
};

export default customization;

export const selectCustomFieldData = state =>
  state.customization?.customFields?.data;
