import React from 'react';

export const ComponentLoading = () => {
  return (
    <div className="component-loading">
      <i className="fas fa-spinner fa-spin fa-3x marg-t-xl" />
    </div>
  );
};

export default ComponentLoading;
