import './account-switched-initializer';
import './active-module-initializer';
import './axios-initializer';
import './bugsnag-initializer';
import './cognito-initializer';
import './cookie-toast-initializer';
import './google-analytics-initializer';
import { initializers } from './initializers';
import './moment-initializer';
import './toast-initializer';
import './token-exchange-initializer';
import './userpilot-initializer';

initializers.forEach(initializer => initializer());
