export const defaultPaginationConfig = {
  defaultSize: 200,
  prefix: 'page-size-',
  sizes: [25, 50, 100, 200]
};

export const paginateSearchResults = (url, searchTerm) => {
  if (url.indexOf('?') > -1) {
    const urlParts = url.split('?');
    return `${urlParts[0]}/search/${searchTerm}?${urlParts[1]}`;
  } else {
    return url;
  }
};

/**
 *
 * @param {String} sizeOption
 * @param {String} selfUrl
 * @returns {String} url
 */
export const changePageSize = (sizeOption, selfUrl) => {
  const pageSize = Number(
    sizeOption.replace(defaultPaginationConfig.prefix, '')
  );
  if (isNaN(pageSize)) {
    return selfUrl; // couldn't find a suitable number to use for page size param
  } else {
    const url = new URL(selfUrl);
    const searchParams = url.searchParams;
    searchParams.set('page', '0');
    searchParams.set('size', pageSize.toString());
    url.search = searchParams.toString();

    return url.href;
  }
};

export default defaultPaginationConfig;
