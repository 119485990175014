const importsState = {
  availablePlatformsForImport: []
};

export const imports = (state = importsState, action) => {
  switch (action.type) {
    case 'SET_AVAILABLE_PLATFORMS_FOR_IMPORT':
      return Object.assign({}, state, {
        availablePlatformsForImport: action.payload
      });

    default:
      return state;
  }
};
