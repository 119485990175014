import download from 'downloadjs';
import moment from 'moment-timezone';

import {
  accountSearch,
  accountSearchWithQuery,
  defaultColumns,
  runCurrentQuery,
  standardColumns
} from '../../Services/GraphQL';
import store from '../../Services/Store';
import { downloadWatchExport } from '../../Services/Waits';
import { Toast as toast } from '../../Shared/Toast/Toast';
import { getActiveModule } from '../../Utils/PortalProducts';

const PAGING_WARNING =
  'Only the first 10,000 result are available. Filter results to view more detections';

export const getDetections = (columns, filters, page, search, sort) => {
  const currentSearch = store.getState().detections?.searchTerm;
  if (!search) {
    search = currentSearch;
  }
  return dispatch => {
    dispatch(setIsLoadingDetections());
    columns = columns?.filter(
      column => !standardColumns.includes(column.field)
    );
    if (!Array.isArray(columns) || !columns.length) {
      columns = defaultColumns[getActiveModule().name];
    }

    return accountSearch(columns, filters, page, null, search, sort)
      .then(res => {
        const search = {
          bulkSearch: res.variables?.urls,
          search: res.variables?.searchTerm
        };
        dispatch(setSearchTerm(search));
        dispatch(setColumns(columns));
        dispatch(setDetections(res.accountSearch));
        dispatch(setFilters(res.variables?.filter));
        return res;
      })
      .catch(() => {
        dispatch(setDetections({ searchResults: [] }));
      });
  };
};

export const getDetectionsWithCurrentQuery = () => {
  return dispatch => {
    dispatch(setIsLoadingDetections());

    return runCurrentQuery()
      .then(res => {
        const search = {
          bulkSearch: res.variables?.urls,
          search: res.variables?.searchTerm
        };
        dispatch(setSearchTerm(search));
        dispatch(setColumns(res.columns));
        dispatch(setDetections(res.accountSearch));
        dispatch(setFilters(res.variables?.filter));
      })
      .catch(() => {
        dispatch(setDetections({ searchResults: [] }));
      });
  };
};

export const getDetectionsWithQuery = (query, variables, columns) => {
  return dispatch => {
    dispatch(setIsLoadingDetections());
    return accountSearchWithQuery(query, variables, columns)
      .then(res => {
        const search = {
          bulkSearch: res.variables?.urls,
          search: res.variables?.searchTerm
        };
        dispatch(setSearchTerm(search));
        dispatch(setColumns(columns));
        dispatch(setDetections(res.accountSearch));
        dispatch(setFilters(res.variables?.filter));
        return res;
      })
      .catch(() => {
        dispatch(setDetections({ searchResults: [] }));
      });
  };
};

export const changePage = (verb, columns, filters, page, search, sort) => {
  window.localStorage.removeItem('scrollData');
  const maxPageDepth = 10000 / page.size - 1;
  // Whether paging via verb or through pageSize (number verb) show warning if going too deep
  if (
    (page.number + 1 > maxPageDepth && verb === 'next') ||
    (!Number.isNaN(verb) && verb > maxPageDepth)
  ) {
    toast.warning(PAGING_WARNING);
  }
  switch (verb) {
    case 'next':
      page.number =
        page.number + 1 > maxPageDepth ? maxPageDepth : page.number + 1;
      break;
    case 'prev':
      page.number = page.number - 1 <= 0 ? 0 : page.number - 1;
      break;
    case 'first':
      page.number = 0;
      break;
    case 'last':
      page.number =
        maxPageDepth <= page.totalPages ? maxPageDepth : page.totalPages - 1;
      break;
    default:
      // Normal paging verb not passed, set page number
      if (!Number.isNaN(verb)) {
        if ((verb + 1) * page.size > 10000) {
          page.number = 10000 / page.size - 1;
        } else if (verb + 1 <= page.totalPages) {
          page.number = verb;
        } else {
          page.number = page.totalPages - 1;
        }
      }
      break;
  }
  if (page.number > maxPageDepth) {
    page.number = maxPageDepth;
  }
  return getDetections(columns, filters, page, search, sort);
};

export const changePageSize = (size, columns, filters, page, search, sort) => {
  page.size = size;
  return getDetections(columns, filters, page, search, sort);
};

export const downloadExport = url => {
  return downloadWatchExport(url).then(res => {
    // TODO: Download with file name attached to response Content Disposition header
    download(
      res.data,
      `brand-track-${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
    );
  });
};

export const setViewMode = viewMode => {
  return dispatch => {
    return dispatch(setViewModeValue(viewMode));
  };
};

export const setViewModeValue = payload => {
  return {
    payload,
    type: 'SET_VIEW_MODE'
  };
};

export const setDetections = payload => {
  return {
    payload,
    type: 'SET_DETECTIONS'
  };
};

export const setColumns = payload => {
  return {
    payload,
    type: 'SET_COLUMNS'
  };
};

const setIsLoadingDetections = () => {
  return {
    type: 'SET_IS_LOADING_DETECTIONS'
  };
};

export const setFilters = payload => {
  return {
    payload,
    type: 'SET_FILTERS'
  };
};

export const setSearchTerm = payload => {
  return {
    payload,
    type: 'SET_SEARCH_TERM'
  };
};

export const setClosedReasonPopover = payload => {
  return {
    payload,
    type: 'SET_CLOSED_REASON_POPOVER'
  };
};
