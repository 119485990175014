import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton } from '@mui/material';
import React from 'react';
import { toast } from 'react-toastify';

export const Toast = {
  ...toast,
  error: (message, options) =>
    toast.error(
      <React.Fragment>
        <i
          className={`fas fa-exclamation-circle Toastify__toast-toastIcon${
            options?.promptOptions ? '--withPrompt' : ''
          }`}
        ></i>
        <div
          className={`Toastify__toast-textWrapper${
            options?.promptOptions ? '--withPrompt' : ''
          }`}
        >
          {message}
        </div>
        {options?.promptOptions && (
          <Button
            className="Toastify__toast-promptButton Toastify__toast-promptButton--error"
            onClick={options?.promptOptions?.onPromptClick}
          >
            {options?.promptOptions?.promptButtonText ?? 'Details'}
          </Button>
        )}
      </React.Fragment>,
      options
    ),
  info: (message, options) =>
    toast.info(
      <React.Fragment>
        <i
          className={`fas fa-info-circle Toastify__toast-toastIcon${
            options?.promptOptions ? '--withPrompt' : ''
          }`}
        ></i>
        <div
          className={`Toastify__toast-textWrapper${
            options?.promptOptions ? '--withPrompt' : ''
          }`}
        >
          {message}
        </div>
        {options?.promptOptions && (
          <Button
            className="Toastify__toast-promptButton Toastify__toast-promptButton--info"
            onClick={options?.promptOptions?.onPromptClick}
          >
            {options?.promptOptions?.promptButtonText ?? 'Details'}
          </Button>
        )}
      </React.Fragment>,
      options
    ),
  success: (message, options) =>
    toast.success(
      <React.Fragment>
        <i
          className={`fas fa-check-circle Toastify__toast-toastIcon${
            options?.promptOptions ? '--withPrompt' : ''
          }`}
        ></i>
        <div
          className={`Toastify__toast-textWrapper${
            options?.promptOptions ? '--withPrompt' : ''
          }`}
        >
          {message}
        </div>
        {options?.promptOptions && (
          <Button
            className="Toastify__toast-promptButton Toastify__toast-promptButton--success"
            onClick={options?.promptOptions?.onPromptClick}
          >
            {options?.promptOptions?.promptButtonText ?? 'Details'}
          </Button>
        )}
      </React.Fragment>,
      options
    ),
  warn: (message, options) =>
    toast.warning(
      <React.Fragment>
        <i
          className={`fas fa-exclamation-triangle Toastify__toast-toastIcon${
            options?.promptOptions ? '--withPrompt' : ''
          }`}
        ></i>
        <div
          className={`Toastify__toast-textWrapper${
            options?.promptOptions ? '--withPrompt' : ''
          }`}
        >
          {message}
        </div>
        {options?.promptOptions && (
          <Button
            className="Toastify__toast-promptButton Toastify__toast-promptButton--warning"
            onClick={options?.promptOptions?.onPromptClick}
          >
            {options?.promptOptions?.promptButtonText ?? 'Details'}
          </Button>
        )}
      </React.Fragment>,
      options
    ),
  warning: (message, options) => Toast.warn(message, options)
};

export const ToastCloseButton = ({ closeToast }) => (
  <IconButton onClick={closeToast}>
    <FontAwesomeIcon icon={faTimes} />
  </IconButton>
);
