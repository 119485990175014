const initialState = {
  isLoading: false,
  paging: {},
  results: [],
  searchLinks: [],
  selectedDomain: null,
  selectedDomainLabels: { labels: [] }
};

const searchResults = (state = initialState, action) => {
  switch (action.type) {
    case 'DOMAIN_SEARCH_RESULTS':
      return Object.assign({}, state, {
        paging: action.payload.page || [],
        results: action.payload._embedded
          ? action.payload._embedded.searchResults
          : action.payload,
        searchLinks: action.payload._links || []
      });
    case 'DOMAIN_SEARCH_RESULTS_CLEAR':
      return Object.assign({}, state, {
        paging: [],
        results: [],
        searchLinks: []
      });
    case 'DOMAIN_SEARCH_RESULTS_SELECT_DOMAIN':
      return Object.assign({}, state, {
        selectedDomain: action.payload._embedded.offering
      });
    case 'DOMAIN_SELECTED_DOMAIN_LINKS':
      return Object.assign({}, state, {
        selectedDomainLinks: action.payload
      });
    case 'DOMAIN_SEARCH_RESULTS_SELECT_DOMAIN_LABELS':
      return Object.assign({}, state, {
        selectedDomainLabels: action.payload
      });
    case 'DOMAIN_SEARCH_RESULTS_SELECT_DOMAIN_CLEAR_LABELS':
      return Object.assign({}, state, {
        selectedDomainLabels: { labels: [] }
      });
    case 'DOMAIN_SEARCH_RESULTS_SELECT_DOMAIN_CLEAR':
      return Object.assign({}, state, { selectedDomain: null });
    case 'ADD_LABEL_RESPONSE':
      return Object.assign({}, state, {
        addLabelResponse: action.payload
      });
    case 'DOMAIN_SEARCH_RESULTS_SET_LOADING':
      return Object.assign({}, state, { isLoading: action.payload });
    default:
      return state;
  }
};

export default searchResults;
