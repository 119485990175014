import axios from 'axios';
import has from 'has';
import { debounce } from 'lodash';
import { event } from 'react-ga';

import { portalConfig } from '../Config/config';
import { Toast as toast } from '../Shared/Toast/Toast';
import * as pagination from '../Utils/Pagination';
import { getActiveModule, getCurrentAppName } from '../Utils/PortalProducts';
import { adjustForPlural } from '../Utils/adjustForPlural';
import store from './Store';

/**
 * @typedef {Object<string, any>} ObjectLiteral
 */

export const waitsUrl = portalConfig.REACT_APP_WAITS_URL;

export const paging = {
  page: 0,
  pageSize: pagination.defaultPaginationConfig.defaultSize,
  totalPages: null
};

/**
 * Check if Waits API is in maintenance mode. Only resolve with true
 * if the response is successful and is not `'alive'`.
 * @returns {Promise} resolves with boolean indicating if API is in maintenance mode
 */
export const checkIfUnderMaintenance = () =>
  axios
    .get(`${waitsUrl}/maintenance/status`)
    .then(r => r?.data !== 'alive')
    .catch(() => Promise.resolve(false));

export const getAccountId = () => {
  return store.getState().accounts?.currentAccount?.accountId;
};

export const hasPermission = (key, permissionsType) => {
  const { permissions } = store.getState();
  const currentProduct = getCurrentAppName(true);
  return (
    (permissions[currentProduct] &&
      has(permissions[permissionsType ?? currentProduct], key)) ||
    has(permissions, key)
  );
};

/**
 * Creates a request for a list of results based on endpoint url and checks
 * access permissions for that endpoint
 * @param {String} url endpoint for the request
 * @param {String} queryProp property to look for request permissions
 * @param {Object} searchString
 * @param {String} [searchString.search]
 * @param {String} [searchString.bulkSearch]
 * @deprecated
 */
export const createRequest = (url, queryProp, searchString) => {
  const currentApp = getCurrentAppName(true);
  const urlPattern = /^(http|https):\/\//;
  const permissions = store.getState().permissions;
  if (!url && permissions[currentApp] && permissions[currentApp][queryProp]) {
    url = store
      .getState()
      .permissions[currentApp][queryProp].href.replace(/\{[^}]*\}/g, '');

    if (
      searchString &&
      searchString.search &&
      urlPattern.test(searchString.search)
    ) {
      url = url.includes('?')
        ? `${url}&urls=${encodeURIComponent(searchString.search)}`
        : `${url}?urls=${encodeURIComponent(searchString.search)}`;
    } else if (searchString && searchString.search) {
      url = url
        .replace(/(searchTerm=)[^&]+/, '')
        .replace(/&&/g, '&')
        .replace('?&', '?');
      url = url.includes('?')
        ? `${url}&searchTerm=${encodeURIComponent(searchString.search)}`
        : `${url}?searchTerm=${encodeURIComponent(searchString.search)}`;
    } else if (searchString && searchString.bulkSearch) {
      const searchUrls = searchString.bulkSearch.split('\n');
      let urlString = url.includes('?') ? '&' : '?';
      for (let i = 0; i < searchUrls.length; i++) {
        urlString = `${urlString}${
          i === 0 ? 'urls=' : '&urls='
        }${encodeURIComponent(searchUrls[i])}`;
      }
      url = `${url}${urlString}`;
    }

    return axios.get(url);
  } else if (!url) {
    return Promise.reject({ data: [] });
  } else {
    if (
      searchString &&
      searchString.search &&
      urlPattern.test(searchString.search)
    ) {
      url = url.includes('?')
        ? `${url}&urls=${encodeURIComponent(searchString.search)}`
        : `${url}?urls=${encodeURIComponent(searchString.search)}`;
    } else if (searchString && searchString.search) {
      url = url
        .replace(/(searchTerm=)[^&]+/, '')
        .replace(/&&/g, '&')
        .replace('?&', '?');
      url = url.includes('?')
        ? `${url}&searchTerm=${encodeURIComponent(searchString.search)}`
        : `${url}?searchTerm=${encodeURIComponent(searchString.search)}`;
    } else if (searchString && searchString.bulkSearch) {
      const searchUrls = searchString.bulkSearch.split('\n');
      let urlString = url.includes('?') ? '&' : '?';
      for (let i = 0; i < searchUrls.length; i++) {
        urlString = `${urlString}${
          i === 0 ? 'urls=' : '&urls='
        }${encodeURIComponent(searchUrls[i])}`;
      }
      url = `${url}${urlString}`;
    }

    return axios.get(url);
  }
};

/**
 *
 * @param {String} url
 * @param {String} method
 * @param {ObjectLiteral} [data]
 * @param {ObjectLiteral} [headers]
 * @deprecated
 * @returns {Promise}
 */
export const generalWaitsRequest = (url, method, data = {}, headers = {}) =>
  axios({
    data,
    headers,
    method,
    url
  });

/**
 * Get one or more detections by documentType and UID
 * @param {Object} requestBody Set a property on the requestBody for
 * each documentType. Value for each documentType property is an array
 * of UIDs. Ex: `{ "APP": [ "Apple_App_Store_id322429835", "Apple_App_Store_id9999999" ] }`
 * @async
 * @returns Promise which resolves with array of detections
 */
export const getDetectionsByDocumentTypeAndUID = async requestBody => {
  const response = await axios.post(
    `${waitsUrl}/offerings/${getAccountId()}`,
    requestBody
  );

  const detectionsGroupedByDocumentType = response.data.detections;
  const flattenedDetections = Object.keys(detectionsGroupedByDocumentType)
    .map(key => detectionsGroupedByDocumentType[key])
    .flat();

  return flattenedDetections;
};

/**
 *
 * @param {String} appId
 * @returns {Promise} axios response
 */
export const getOffering = appId => offeringRequest(appId);

/**
 *
 * @param {String} uid
 * @param {String} documentType
 * @returns {Promise} axios response
 */
export const getGenericOffering = (uid, documentType) =>
  genericOfferingRequest(uid, documentType);

/**
 *
 * @param {String} accountOfferingUrl
 * @param {ObjectLiteral} details
 * @returns {Promise} axios response
 */
export const addAccountOfferingDetails = (accountOfferingUrl, details) => {
  return axios.post(`${accountOfferingUrl}`, details);
};

/**
 *
 * @param {String} appId
 * @returns {Promise} axios response
 */
const offeringRequest = appId => {
  return axios.get(
    store
      .getState()
      .permissions[
        getCurrentAppName(true) === 'brand' ? 'import' : getCurrentAppName(true)
      ]['account-offering-template'].href.replace(
        /{offeringUid}/g,
        encodeURIComponent(appId)
      )
  );
};

// To distinguish between Domain and Social, which both have multiple documentTypes
const domainDocumentTypes = ['DOMAIN', 'WEBSITE', 'WEBSITES'];

/**
 *
 * @param {String} uid
 * @param {String} documentType
 * @returns {Promise}
 */
const genericOfferingRequest = (uid, documentType) => {
  // TODO: Have the backend change the root links to help us out a little
  let url;
  let urls = store.getState().permissions[documentType.toLowerCase()];
  if (!urls) {
    urls = domainDocumentTypes.includes(documentType)
      ? store.getState().permissions.domain
      : store.getState().permissions.social;
  }
  if (Array.isArray(urls['account-offering-template'])) {
    url = urls['account-offering-template'].find(
      filterUrl => filterUrl.type === documentType
    ).href;
  } else {
    url = urls['account-offering-template'].href;
  }
  url = url.replace(/{offeringUid}/g, encodeURIComponent(uid));

  return axios.get(url);
};

// WAITS Search Proxy

export let sorting = {
  sort: null,
  sortDir: null,
  sortUrl: null
};

export const setSort = (sort, sortDir, sortUrl) => {
  sorting = {
    sort,
    sortDir,
    sortUrl
  };
};

/**
 *
 * @param {ObjectLiteral} query
 * @param {ObjectLiteral} sort
 * @param {String} type
 * @param {String} url
 * @returns {Promise} axios response
 */
export const search = (query, sort, type, url) => {
  if (sort && sort.sort && sort.sortDir) {
    query.sortDirection = sort.sortDir;
    query.sortOn = sort.sort;
  } else {
    delete query.sortDirection;
    delete query.sortOn;
  }
  let newUrl = url;
  const pagingParams = `?page=${encodeURIComponent(
    paging.page
  )}&size=${encodeURIComponent(paging.pageSize)}`;
  if (!newUrl) {
    newUrl = store.getState().permissions[type]['search'].href;
  }
  newUrl = newUrl.replace(/{\?page,size}/, pagingParams);
  return axios.post(newUrl, query, { timeout: 30000 });
};

/**
 *
 * @param {String} url
 * @returns {Promise} axios response
 */
export const downloadWatchExport = url => {
  return axios({ method: 'GET', responseType: 'blob', url });
};

/**
 *
 * @param {String} url
 * @param {ObjectLiteral} file
 * @param {Blob} fileBinary
 * @returns {Promise} axios response
 */
export const uploadAttachment = (url, file, fileBinary) => {
  return generalWaitsRequest(`${url}/uploads`, 'POST', {}).then(res => {
    // Remove auth header for S3 request
    const instance = axios.create();
    delete instance.defaults.headers.common['Authorization'];
    return instance({
      data: fileBinary,
      headers: { 'Content-Type': file.type },
      method: 'PUT',
      url: res.data._links.self.href
    }).then(() => {
      return generalWaitsRequest(res.data._links.metadata.href, 'POST', {
        contentType: file.type,
        description: `${res.data.entityType} attachment`,
        fileName: file.name,
        key: res.data.key
      }).then(() => {
        return res;
      });
    });
  });
};

export const uploadGenericCSV = file => {
  const url = `${waitsUrl}/offerings/import/${getAccountId()}`;
  const data = new FormData();
  data.append('offering-import', file);
  return generalWaitsRequest(url, 'POST', data, {
    'Content-Type': 'multipart/form-data'
  });
};

/**
 *
 * @returns {Promise}
 */
export const getCountries = () => {
  if (hasPermission('countries')) {
    return axios.get(store.getState().permissions.countries.href, {
      headers: {
        Authorization: ' '
      }
    });
  } else {
    return Promise.reject();
  }
};

/**
 *
 * @returns {Promise|undefined}
 */
export const getStates = () => {
  if (hasPermission('countries')) {
    return axios.get(
      store
        .getState()
        .permissions.countries.href.replace(/countries/g, 'states'),
      {
        headers: {
          Authorization: ' '
        }
      }
    );
  }
};

/**
 *
 * @param {String} type
 * @param {String} method
 * @param {ObjectLiteral} data
 * @param {String} [url]
 * @param {String} [searchString]
 * @param {ObjectLiteral} [paging]
 * @param {String} [queryString]
 * @returns {Promise}
 */
export const generalCaseRequest = (
  type,
  method,
  data,
  url,
  searchString,
  paging,
  queryString
) => {
  if (!paging && method === 'GET') {
    paging = { page: 0, size: 200 };
  }
  const queryStringPattern = new RegExp(/\?+/g);
  if (url) {
    return axios({
      data,
      method,
      url
    });
  } else if (hasPermission(type, 'case')) {
    url = store
      .getState()
      .permissions.case[type].href.replace(/\{[^}]*\}/g, '');
    if (searchString) {
      url = `${url}${
        queryStringPattern.test(url) ? '&' : '?'
      }search=${encodeURIComponent(searchString)}`;
    }
    if (paging) {
      url = `${url}${queryStringPattern.test(url) ? '&' : '?'}page=${
        paging.page
      }&size=${paging.size}`;
    }
    if (queryString) {
      url = `${url}${queryStringPattern.test(url) ? '&' : '?'}${queryString}`;
    }
    return axios({
      data,
      method,
      url
    });
  } else {
    return Promise.reject(new Error('No case url found'));
  }
};

/**
 *
 * @param {ObjectLiteral} payload
 * @param {String} product
 * @returns {Promise} axios response
 */
export const addToAccount = (payload, product) => {
  return axios
    .post(
      `${waitsUrl}/offerings/${getAccountId()}/${product}/add-from-search`,
      payload
    )
    .then(response => {
      try {
        event({
          action: 'Add to Account',
          category: 'Bulk Update',
          label: getActiveModule().getDisplayName(),
          value: payload?.offeringUids?.length ?? 0
        });
      } catch (error) {
        // no op
      }
      return response;
    });
};

/**
 *
 * @param {ObjectLiteral} payload
 * @returns {Promise} axios response
 */
export const bulkUpdate = payload => {
  const url = `${waitsUrl}/offerings/${getAccountId()}/update`;
  return axios.post(url, payload).then(response => {
    try {
      event({
        action: 'Bulk Update',
        category: 'Bulk Update',
        label: getActiveModule().getDisplayName(),
        value: payload?.offerings?.length ?? 0
      });
    } catch (error) {
      // no op
    }
    return response;
  });
};

export const bulkUpdateDetectionsByViewType = (viewType, payload) => {
  const url = `${waitsUrl}/offerings/${getAccountId()}/v2/update/${viewType}`;
  const contentType =
    payload.type === 'BulkUpdateByViewRequestDTO'
      ? 'vnd.bulk-update.by-view.v2'
      : 'vnd.bulk-update.by-offering.v2';
  return axios
    .post(url, payload, {
      headers: {
        'content-type': `application/${contentType}+json`
      }
    })
    .then(res => res);
};

/**
 *
 * @param {String} detectionId
 * @param {ObjectLiteral} payload
 * @returns {Promise} axios response
 */
export const updateStatusOfImportedDetection = (detectionId, payload) => {
  const url = `${waitsUrl}/offerings/${getAccountId()}/IMPORT/${detectionId}`;
  return axios
    .post(url, payload)
    .then(() => {
      toast.success('Successfully updated the Status');
    })
    .catch(() => {
      toast.error('Unable to update the Status at this time');
    });
};

/**
 *
 * @param {String} urls
 * @returns {Promise} axios response
 */
export const searchByURLs = urls => {
  return axios.post(
    `${waitsUrl}/watches/${getAccountId()}/APP/search/bulk-request`,
    { urls }
  );
};

const debouncedRequest = debounce((url, resolve, reject) => {
  return axios.get(url).then(resolve).catch(reject);
}, 500);

/**
 *
 * @param {String} searchTerm
 * @param {String} filterName
 * @returns {Promise} axios response
 */
export const searchFilters = (searchTerm, filterName, size = 100) => {
  let url = `${waitsUrl}/filter/${getAccountId()}/${getCurrentAppName()}/aggregated/search?name=${encodeURIComponent(
    filterName
  )}&size=${size}`;
  if (searchTerm) {
    url = `${url}&searchTerm=${encodeURIComponent(searchTerm)}`;
  }

  return new Promise((resolve, reject) => {
    if (searchTerm) {
      debouncedRequest(url, resolve, reject);
    } else {
      return axios.get(url).then(resolve).catch(reject);
    }
  });
};

export const searchDefaultFilterValues = (
  searchTerm,
  filterName,
  size = 100
) => {
  let url = `${waitsUrl}/filter/${getAccountId()}/${getCurrentAppName()}/search/defaults?name=${encodeURIComponent(
    filterName
  )}&size=${size}`;
  if (searchTerm) {
    url = `${url}&searchTerm=${encodeURIComponent(searchTerm)}`;
  }

  return new Promise((resolve, reject) => {
    if (searchTerm) {
      debouncedRequest(url, resolve, reject);
    } else {
      return axios.get(url).then(resolve).catch(reject);
    }
  });
};

/**
 *
 * @returns {Promise} axios response
 */
export const categoriesRequest = () => {
  const url = `${waitsUrl}/filter/${getAccountId()}/APP/search?size=300&name=storeCategory`;
  return axios.get(url);
};

/**
 *
 * @param {Object} searchTermObject
 * @param {String} searchTermObject.searchTerm
 * @returns {Promise} axios response
 */
export const basicSearchRequest = ({ searchTerm }) => {
  const url = `${waitsUrl}/search/${getAccountId()}?size=200&search=${encodeURIComponent(
    searchTerm
  )}`;
  return axios.get(url);
};

// checks if urls are importable, or if csv import is necessary
export const checkUrls = urls => {
  const url = `${waitsUrl}/uid/bulk/url/info`;
  return axios.post(
    url,
    { urls },
    {
      headers: { 'Cache-Control': 'no-cache' }
    }
  );
};

/**
 *
 * @returns {Promise} axios response
 */
export const getEmailTemplateFields = () => {
  return axios.get(`${waitsUrl}/case/${getAccountId()}/email_templates/types`);
};

/**
 *
 * @param {ObjectLiteral} payload
 * @param {String} moduleName
 */
export const adhocCrawl = (payload, moduleName) => {
  return axios
    .post(
      `${waitsUrl}/offerings/adhoc-crawl/${getAccountId()}/${moduleName}`,
      payload
    )
    .then(res => {
      if (!res.data?.errors?.length) {
        toast.success('The detections were sent to be updated');
      } else {
        const errorUids = res.data.errors.map(error => error.uid);
        const payloadUids =
          moduleName === 'DOMAIN' ? payload.domainList : payload.uids;
        const errorMap = res.data.errors.reduce((acc, error) => {
          acc[error.uid] = error.message;
          return acc;
        }, {});
        // PORT-5742: only applicable to Apps
        const sourceSearchApiErrorMessage =
          "Detections with source 'SEARCH' cannot be recrawled";
        const sourceSearchErrorList = payloadUids.filter(
          uid =>
            errorUids.includes(uid) &&
            errorMap[uid].includes(sourceSearchApiErrorMessage)
        );
        const errorList = payloadUids.filter(
          uid => errorUids.includes(uid) && !sourceSearchErrorList.includes(uid)
        );
        const successList = payloadUids.filter(
          uid =>
            !sourceSearchErrorList.includes(uid) && !errorList.includes(uid)
        );
        const sourceSearchErrorMessage = `Apps ${sourceSearchApiErrorMessage}: ${sourceSearchErrorList.join(
          ', '
        )}`;
        const errorMessage = `The following detections could not be sent to be updated: ${errorList.join(
          ', '
        )}`;
        const successMessage = ` ${successList.length} ${
          successList.length === 1 ? 'detection was' : 'detections were'
        } successfully sent to be updated`;
        sourceSearchErrorList.length &&
          toast.error(sourceSearchErrorMessage, { autoClose: false });
        errorList.length && toast.error(errorMessage, { autoClose: false });
        successList.length &&
          toast.success(successMessage, { autoClose: false });
      }
    })
    .catch(() => {
      toast.error('An error occurred trying to update the detection data');
    });
};

/**
 * @param {String[]} urls
 * @param {String} viewType
 * @param {Boolean} isWebsite
 * @returns {Promise} response object: { scheduled: [], errors: [], scheduledMessage: '', errorsMessage: '' }
 */
export const importUrl = (urls, viewType, isWebsite = false) =>
  new Promise((resolve, reject) =>
    axios
      .post(
        `${waitsUrl}/offerings/import/${getAccountId()}/${viewType}${
          isWebsite ? '?entityType=WEBSITES' : ''
        }`,
        { urls }
      )
      .then(response => {
        // When submitting for APP, we get back a limited response.
        const scheduled = viewType !== 'APP' ? response?.data?.scheduled : urls;
        const errors = response?.data?.errors;
        const scheduledMessage =
          scheduled?.length &&
          `Successfully sent the ${adjustForPlural(
            'URL',
            scheduled.length
          )} to be crawled and added to the account`;
        const errorsMessage =
          errors?.length &&
          `An error occurred importing ${errors.length} ${adjustForPlural(
            'URL',
            errors.length
          )} `;
        resolve({ errors, errorsMessage, scheduled, scheduledMessage });
      })
      .catch(err => {
        const errors = urls;
        const errorsMessage =
          err?.response?.data?.message ||
          `An error occurred importing ${errors.length} ${adjustForPlural(
            'URL',
            errors.length
          )} `;

        reject({ errors, errorsMessage });
      })
  );

/**
 *
 * @param {String} copyrightsUrl
 * @param {String} trademarksUrl
 * @returns {Promise} axios responses
 */
export const getTwitterSetupFields = async (copyrightsUrl, trademarksUrl) => {
  const promises = [axios.get(copyrightsUrl), axios.get(trademarksUrl)];
  const res = await Promise.all(promises);
  return res;
};

/**
 *
 * @param {String} entityType
 * @param {ObjectLiteral} payload
 * @returns {Promise} axios response
 */
export const enforcementValidation = async (entityType, payload) => {
  const url = `${waitsUrl}/case/canEnforce/${getAccountId()}/${entityType}`;
  return axios.post(url, payload);
};

export const getExplainabilityDetailsRequest = (moduleType, uid) => {
  const url = `${waitsUrl}/enrichment/${moduleType}/${getAccountId()}/${uid}`;
  return axios.get(url);
};

export const getExplainabilityDetailsByVersionRequest = (
  moduleType,
  uid,
  versionId
) => {
  const url = `${waitsUrl}/enrichment/${moduleType}/${getAccountId()}/${uid}/${versionId}`;
  return axios.get(url);
};

export const getExplainabilityVersionListRequest = (moduleType, uid) => {
  const url = `${waitsUrl}/enrichment/${moduleType}/${getAccountId()}/${uid}/versions`;
  const response = axios.get(url);
  return response;
};

export const getOfferingByUrl = url => {
  return axios.get(url);
};
