import axios from 'axios';
import jwt_decode from 'jwt-decode';

const STORAGE_KEY = 'adxBearerToken';
const HEADER_KEY = 'Authorization';
let jwtToken;

/**
 * Set auth Bearer header for Axios requests
 * @param {String} token
 * @returns {void}
 */
const setAuthorizationHeader = token => {
  axios.defaults.headers.common[HEADER_KEY] = `Bearer ${token}`;
};

/**
 * Clear auth Bearer header for Axios requests
 * @returns {void}
 */
const clearAuthorizationHeader = () => {
  delete axios.defaults.headers.common[HEADER_KEY];
};

/**
 * Store token in localStorage
 * @param {String} token
 * @returns {void}
 */
const storeToken = token => window.localStorage.setItem(STORAGE_KEY, token);

/**
 * Remove token from localStorage
 * @returns {void}
 */
const clearStoredToken = () => window.localStorage.removeItem(STORAGE_KEY);

/**
 * Store the token in localStorage and additionally set the axios
 * Authorization header
 * @param {String} token the JWT token
 * @returns {String} token
 */
export const setToken = token => {
  clearStoredToken();
  jwtToken = token;
  setAuthorizationHeader(token);
  storeToken(token);
  return token;
};

/**
 * Get the JWT token
 * @returns {String} token
 */
export const getToken = () =>
  jwtToken || window.localStorage.getItem(STORAGE_KEY);

/**
 * Get decoded token
 * @returns {Object} decoded token or empty object
 */
export const getDecodedToken = () => {
  const response = {};
  const encodedToken = getToken();

  if (encodedToken) {
    const decodedToken = jwt_decode(encodedToken);
    Object.keys(decodedToken).forEach(key => {
      try {
        response[key] = JSON.parse(decodedToken[key]);
      } catch (e) {
        response[key] = decodedToken[key];
      }
    });
  }

  return response;
};

/**
 * Removes stored token and axios header.
 * @returns {void}
 */
export const clearToken = () => {
  jwtToken = null;
  clearStoredToken();
  clearAuthorizationHeader();
};

/**
 * Get expiration date
 * @returns {Date}
 */
const getTokenExpirationDate = () => {
  const decodedToken = getDecodedToken();
  const exp = decodedToken?.exp;

  if (typeof exp === 'number') {
    return new Date(exp * 1000);
  }
};

/**
 * Milliseconds to refresh token
 * @type {Number}
 */
export const ALMOST_EXPIRED = 1000 * 60 * 5;

/**
 * Is Appdetex auth token is almost expired.
 * @returns {Boolean}
 */
export const isTokenAlmostExpired = () => {
  const expirationDate = getTokenExpirationDate();

  if (!expirationDate) {
    return false;
  }

  const expirationDateLessAlmostExpired = new Date(
    +expirationDate - ALMOST_EXPIRED
  );
  const now = new Date();

  return now > expirationDateLessAlmostExpired;
};

/**
 * Is Appdetex auth token expired?
 * @returns {Boolean}
 */
export const isTokenExpired = () => new Date() > getTokenExpirationDate();

/**
 * Get current account id from token
 * @returns {Number|null} accountId
 */
export const getAccountIdFromToken = () => {
  const accountId = parseInt(
    getDecodedToken()?.acctRoles?.pop()?.accountId,
    10
  );
  return !isNaN(accountId) ? accountId : null;
};
