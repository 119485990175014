import store from '../Services/Store';
import { storeMostRecentModuleName } from '../Services/User';
import { browserHistory } from '../Utils/History';
import { getModuleByPath, setActiveModule } from '../Utils/PortalProducts';
import { setCurrentClientSubscription } from '../store/adminClients/adminClients.actions';
import { initializers } from './initializers';

/**
 * @type {String}
 */
let previousActiveModuleName;

/**
 * @param {String} pathname
 * @returns {void}
 */
const setActive = pathname => {
  const activeModule = getModuleByPath(pathname);

  if (activeModule && activeModule?.name !== previousActiveModuleName) {
    setActiveModule(activeModule);
    // TODO: currentClientSubscriptions is empty after browser refreshing a page. The store is being wiped and this runs before setCurrentClientSubscriptions()
    const activeSubscription = store
      .getState()
      .adminClients.currentClientSubscriptions.find(subscription => {
        if (activeModule.subscriptionType.toLowerCase() === 'domain') {
          return (
            subscription.type.toLowerCase() === 'domain' ||
            subscription.type.toLowerCase() === 'websites'
          );
        } else {
          return subscription.type === activeModule.subscriptionType;
        }
      });

    store.dispatch(setCurrentClientSubscription(activeSubscription));

    storeMostRecentModuleName(activeModule.name);
    previousActiveModuleName = activeModule.name;
  }
};

const activeModuleInitializer = () => {
  browserHistory.listen(location => setActive(location.pathname));
  setActive(window.location.pathname);
};

initializers.push(activeModuleInitializer);
