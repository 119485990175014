import axios from 'axios';

import store from '../../Services/Store';
import * as waits from '../../Services/Waits';
import { Toast as toast } from '../../Shared/Toast/Toast';
import { browserHistory } from '../../Utils/History';
import {
  getCurrentAppName,
  getSubscribedPortalProducts
} from '../../Utils/PortalProducts';

export const saveView = (
  name,
  visibility,
  isPinned,
  columns,
  path,
  searchQuery
) => {
  return dispatch => {
    columns = columns.filter(c => c.field !== 'networks');
    const defaultColumns = [
      {
        field: 'name',
        name: 'Name'
      }
    ];
    if (waits.hasPermission('saved-views')) {
      const payload = {
        columns: columns.length < 1 ? defaultColumns : columns,
        name,
        searchQuery,
        visibility
      };
      if (isPinned) {
        payload.pinRequested = true;
      }
      return axios
        .post(
          store.getState().permissions[getCurrentAppName(true)]['saved-views']
            .href,
          payload
        )
        .then(res => {
          toast.success('Successfully Saved View');
          browserHistory.push(`${path}/${res.data.id}`);
          return dispatch(getAllSavedViews());
        });
    } else {
      return Promise.reject();
    }
  };
};

export const setIsLoadingSavedViews = () => ({
  type: 'SET_IS_LOADING_SAVED_VIEWS'
});

export const updateSavedView = (
  updateUrl,
  name,
  visibility,
  columns,
  searchQuery,
  isPinned,
  menuSort
) => {
  return dispatch => {
    columns = columns?.filter(c => c.field !== 'networks');
    return axios
      .patch(updateUrl, {
        columns,
        menuSort,
        name,
        pinRequested: isPinned,
        searchQuery,
        visibility
      })
      .then(res => {
        toast.success('Successfully Updated Saved View');
        dispatch(setCurrentView(res.data));
        return dispatch(getViews());
      });
  };
};

export const getViews = (url, searchObj) => {
  return dispatch => {
    dispatch(setIsLoadingSavedViews());
    const query = searchObj?.search;
    if (waits.hasPermission('saved-views')) {
      if (!url) {
        url =
          store.getState().permissions[getCurrentAppName(true)]['saved-views'];
        if (url.templated) {
          url = url.href.replace(/{\?.*}/g, '');
        } else {
          url = url.href;
        }
      } else if (url.includes('{')) {
        url = url.replace(/{\?.*}/g, '');
      }
      if (query) {
        url = url.includes('?')
          ? `${url}&search=${encodeURIComponent(query)}`
          : `${url}?search=${encodeURIComponent(query)}`;
      }
      return axios.get(`${url}`).then(res => {
        return dispatch(setSavedViews(res.data));
      });
    } else {
      dispatch(setSavedViews({ _embedded: [] }));
      return Promise.reject();
    }
  };
};

const allSavedViewsRequest = (pinned = false) => {
  if (waits.hasPermission('saved-views')) {
    let allViews = [];

    const getViewPage = url => {
      return axios.get(url).then(res => {
        if (res.data && res.data._embedded) {
          allViews = allViews.concat(res.data._embedded['saved-views']);
        }
        if (res.data && res.data._links && res.data._links.next) {
          url = res.data._links.next.href;
        } else {
          url = undefined;
        }
        return url ? getViewPage(url) : allViews;
      });
    };
    const url = pinned
      ? `${
          store.getState().permissions[getCurrentAppName(true)]['saved-views']
            .href
        }/pinned`
      : store.getState().permissions[getCurrentAppName(true)]['saved-views']
          .href;
    return getViewPage(`${url}?size=100`);
  } else {
    return Promise.reject();
  }
};

export const clearViews = () => {
  return dispatch => {
    dispatch(setSavedViews([]));
  };
};

export const getAllSavedViews = () => {
  return dispatch => {
    dispatch(setIsLoadingSavedViews());
    return allSavedViewsRequest()
      .then(res => {
        return dispatch(setAllSavedViews(res));
      })
      .catch(() => {
        return dispatch(setAllSavedViews(null));
      });
  };
};

export const getSavedView = viewId => {
  return dispatch => {
    if (waits.hasPermission('saved-views')) {
      const url = `${
        store.getState().permissions[getCurrentAppName(true)]['saved-views']
          .href
      }/${viewId}`;
      return axios
        .get(url)
        .then(res => {
          if (res.data) {
            return dispatch(setCurrentView(res.data));
          }
        })
        .catch(() => {
          toast.error('Unable to retrieve saved view');
        });
    } else {
      return Promise.reject();
    }
  };
};

export const getAllPinnedViews = () => {
  return dispatch => {
    return allSavedViewsRequest(true).then(res => {
      return dispatch(setPinnedViews(res));
    });
  };
};

export const addPinnedView = viewId => {
  return dispatch => {
    if (waits.hasPermission('saved-views')) {
      const url =
        store.getState().permissions[getCurrentAppName(true)]['saved-views']
          .href;
      return axios
        .post(`${url}/${viewId}/pin`)
        .then(res => {
          let allSavedViews = store.getState().savedViews.allViews;
          if (allSavedViews.length) {
            allSavedViews = allSavedViews.map(view => {
              if (view.id === res.data.id) {
                return res.data;
              }
              return view;
            });
            dispatch(setAllSavedViews(allSavedViews));
          }
          return res;
        })
        .catch(err => {
          toast.error('There was a problem pinning your view');
          throw err;
        });
    } else {
      return Promise.reject();
    }
  };
};

export const removePinnedView = viewId => {
  return dispatch => {
    if (waits.hasPermission('saved-views')) {
      const url =
        store.getState().permissions[getCurrentAppName(true)]['saved-views']
          .href;
      return axios
        .delete(`${url}/${viewId}/unpin`)
        .then(res => {
          let allSavedViews = store.getState().savedViews.allViews;
          if (allSavedViews.length) {
            allSavedViews = allSavedViews.map(view => {
              if (view.id === res.data.id) {
                return res.data;
              }
              return view;
            });
            dispatch(setAllSavedViews(allSavedViews));
          }
          return res;
        })
        .catch(err => {
          toast.error('There was a problem removing your pinned view');
          throw err;
        });
    } else {
      return Promise.reject();
    }
  };
};

export const reorderViews = views => {
  return dispatch => {
    if (waits.hasPermission('saved-views')) {
      const url =
        store.getState().permissions[getCurrentAppName(true)]['saved-views']
          .href;
      return axios
        .post(`${url}/pinned`, views)
        .then(() => {
          toast.success('Successfully updated Saved Views');
          dispatch(getAllSavedViews());
        })
        .catch(err => {
          toast.error('There was a problem reordering your pinned views');
          throw err;
        });
    } else {
      return Promise.reject();
    }
  };
};

export const getAllPinnedViewsForProduct = product => {
  const url = `${
    waits.waitsUrl
  }/saved-views/${waits.getAccountId()}/${product}/pinned`;
  return axios.get(url);
};

export const getAllPinnedViewsForUser = subscriptions => {
  const subscribedProducts = getSubscribedPortalProducts(subscriptions)
    .filter(product => product.hasSavedViews)
    .map(product => product.viewType || product.subscriptionType);

  if (subscribedProducts.includes('INVESTIGATION')) {
    subscribedProducts.push('TRACER');
  }

  return dispatch => {
    const promises = subscribedProducts.map(subscribedProduct =>
      getAllPinnedViewsForProduct(subscribedProduct)
    );
    const defaultViews = {};
    return Promise.all(promises)
      .then(responses => {
        responses.forEach(response => {
          const product =
            response.data?._embedded?.['saved-views']?.[0]?.viewType;

          if (product) {
            defaultViews[product] = response.data?._embedded?.['saved-views']
              .sort((a, b) => {
                if (a.menuSort < b.menuSort) {
                  return -1;
                } else if (a.menuSort > b.menuSort) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .shift();
          }
        });
        dispatch(setUserDefaultViews(defaultViews));
        return responses;
      })
      .catch(() => {
        toast.error('There was a problem fetching your default views.');
      });
  };
};

export const changePage = (verb, pageNumber) => {
  const links = store.getState().savedViews.links;
  const totalPages = store.getState().savedViews.paging.totalPages;
  let url = links.self.href;

  if (verb) {
    url = links?.[verb]?.href || links.self.href;
  } else if (pageNumber < totalPages) {
    pageNumber = parseInt(pageNumber, 10);
    url = url.replace(/page=\d+/, `page=${pageNumber}`);
  }

  return dispatch => {
    dispatch(setIsLoadingSavedViews());
    return getViews(url);
  };
};

export const deleteView = url => {
  return dispatch => {
    waits.generalWaitsRequest(url, 'DELETE').then(() => {
      toast.success('Successfully Deleted View');
      return dispatch(getViews());
    });
  };
};

export const setSavedViews = views => ({
  payload: views,
  type: 'SET_SAVED_VIEWS'
});

export const setCurrentView = view => ({
  payload: view,
  type: 'SET_CURRENT_VIEW'
});

export const setAllSavedViews = views => ({
  payload: views,
  type: 'SET_ALL_SAVED_VIEWS'
});

export const setPinnedViews = views => ({
  payload: views,
  type: 'SET_PINNED_VIEWS'
});

export const setUserDefaultViews = views => ({
  payload: views,
  type: 'SET_USER_DEFAULT_VIEWS'
});
