const testCatData = {
  categories: []
};

const queries = (state = testCatData, action) => {
  switch (action.type) {
    case 'SET_CATEGORIES':
      return Object.assign({}, state, {
        categories: action.payload
      });
    default:
      return state;
  }
};

export default queries;
