import { muiTheme } from '@appdetex/detect-mui';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React, { Component, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import {
  exchangeCognitoJwtForAppdetexJwt,
  getCognitoJwtToken,
  isAuthenticated,
  signOut
} from './Services/Auth2';
import store from './Services/Store';
import { checkIfUnderMaintenance } from './Services/Waits';
import { browserHistory } from './Utils/History';
import { Loading } from './Utils/Loading';
import MaintenanceComponent from './Utils/MaintenanceComponent';
import { PrivateRoute } from './Utils/PrivateRoute';
import { routes } from './routes';

const SharedRoot = React.lazy(() => import('./Shared/SharedRoot/SharedRoot'));
const LoginContainer = React.lazy(() =>
  import('./Shared/LoginContainer/LoginContainer')
);
const SingleSignOnLanding = React.lazy(() =>
  import('./Shared/SingleSignOn/SingleSignOnLanding').then(module => ({
    default: module.SingleSignOnLanding
  }))
);
const SingleSignOnReturn = React.lazy(() =>
  import('./Shared/SingleSignOn/SingleSignOnReturn').then(module => ({
    default: module.SingleSignOnReturn
  }))
);
const SingleSignOnSignout = React.lazy(() =>
  import('./Shared/SingleSignOn/SingleSignOnSignout').then(module => ({
    default: module.SingleSignOnSignout
  }))
);
const Root = React.lazy(() => import('./Shared/Root/Root'));
const Export = React.lazy(() => import('./Shared/Export/Export'));
const VerifyEmail = React.lazy(() =>
  import('./Shared/VerifyEmail/VerifyEmail').then(module => ({
    default: module.ConnectedVerifyEmail
  }))
);
const CookiePolicy = React.lazy(() =>
  import('./Shared/CookiePolicy/CookiePolicy').then(module => ({
    default: module.CookiePolicy
  }))
);

/**
 * RouterRoot component composes the browser router and Root app component
 */
class RouterRoot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUnderMaintenance: false
    };
  }

  checkMaintenanceStatus() {
    checkIfUnderMaintenance().then(shouldTurnOnMaintenanceMode => {
      this.toggleMaintenanceMode(shouldTurnOnMaintenanceMode);
    });
  }

  componentDidMount() {
    this.checkMaintenanceStatus();
    this.timer = setInterval(() => this.checkMaintenanceStatus(), 60000);

    isAuthenticated()
      .then(getCognitoJwtToken)
      .then(exchangeCognitoJwtForAppdetexJwt)
      .catch(() => {
        // If they're on an SSO page, don't sign them
        // out.
        const { pathname } = window.location;
        if (!pathname.startsWith('/sso')) {
          signOut();
        }
      });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  render() {
    return (
      <>
        {this.state.isUnderMaintenance ? (
          <MaintenanceComponent />
        ) : (
          <Provider store={store}>
            <ThemeProvider theme={createTheme(muiTheme)}>
              <SnackbarProvider
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'top'
                }}
                autoHideDuration={4000}
                domRoot={document.body}
                style={{ maxWidth: 450 }}
              />
              <Router history={browserHistory}>
                <Suspense fallback={<Loading />}>
                  <Switch>
                    <Route
                      component={SingleSignOnLanding}
                      exact
                      path="/sso/:ssoClientId"
                    />
                    <Route
                      component={SingleSignOnReturn}
                      exact
                      path="/sso-return"
                    />
                    <Route
                      component={SingleSignOnSignout}
                      exact
                      path="/sso-signout"
                    />
                    <Route
                      component={LoginContainer}
                      path={routes.LOGIN.basePath}
                    />
                    <Route
                      component={CookiePolicy}
                      path={routes.COOKIE_POLICY.path}
                    />
                    <PrivateRoute
                      component={VerifyEmail}
                      ignoreSubscription={true}
                      path="/verify-email"
                    />
                    <PrivateRoute
                      component={Export}
                      ignoreSubscription={true}
                      path="/export"
                    />
                    <Route component={Root} exact path="/" />
                    <PrivateRoute component={SharedRoot} path="/" />
                  </Switch>
                </Suspense>
              </Router>
            </ThemeProvider>
          </Provider>
        )}
      </>
    );
  }

  toggleMaintenanceMode(shouldBeInMaintenance) {
    if (this.state.isUnderMaintenance !== shouldBeInMaintenance) {
      this.setState(
        { isUnderMaintenance: shouldBeInMaintenance },
        window.console.warn(
          `${shouldBeInMaintenance ? 'Entering' : 'Leaving'} maintenance mode.`
        )
      );
    }
  }
}

// eslint-disable-next-line import/no-default-export
export default RouterRoot;
