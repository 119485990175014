export const normalizeCamelCase = key =>
  key.replace(/([A-Z])/g, ' $1').replace(/^./, key => key.toUpperCase());

export const capitalize = (key, normalizeCamelCase = true) => {
  return key
    .split(' ')
    .map(
      val =>
        val.charAt(0).toUpperCase() +
        (normalizeCamelCase ? val.toLowerCase().slice(1) : val.slice(1))
    )
    .join(' ');
};

export const toCamelCase = key =>
  `${key.charAt(0).toLowerCase()}${key.split(' ').join('').slice(1)}`;

export const toUpperSnakeCase = key => key.toUpperCase().split(' ').join('_');

export const normalizeUpperSnakeCase = key =>
  key
    .split('_')
    .map(val => val.charAt(0).toUpperCase() + val.slice(1))
    .join(' ');

export const convertSnakeCaseToPascalCase = key =>
  key
    .split('_')
    .map(val => val.charAt(0).toUpperCase() + val.slice(1).toLowerCase())
    .join(' ');

export const normalizeKebabCase = key =>
  key
    .split('-')
    .map(val => val.charAt(0).toUpperCase() + val.slice(1).toLowerCase())
    .join(' ');

export const toKebabCase = key => key.toLowerCase().split(' ').join('-');

export const iconStyleStringToObj = string => {
  const finalStyle = stringStyleToObj(string);
  return Object.assign(finalStyle, { maxWidth: '60px' });
};

export const stringStyleToObj = string => {
  if (string.includes('{')) {
    return string;
  } else if (string.toUpperCase() === 'NONE') {
    return {};
  } else {
    const styles = string.split(';');
    const styleObj = styles.map(style => {
      if (style) {
        const dashIndex = style.indexOf('-');
        if (dashIndex >= 0) {
          const unQuotedStyle = style
            .split('-')
            .map((style, index) => {
              return index
                ? `${style.charAt(0).toUpperCase()}${style.slice(1)}`
                : style;
            })
            .join('');

          const quoteArray = unQuotedStyle.split(':');
          style = `{"${quoteArray[0].trim()}": "${quoteArray[1].trim()}"}`;
        }
        try {
          return JSON.parse(style);
        } catch (e) {
          return {};
        }
      } else {
        return {};
      }
    });
    return styleObj.reduce((prevValue, currentValue) => {
      return currentValue ? Object.assign(prevValue, currentValue) : prevValue;
    });
  }
};
