import DiscoveryIcon from '@appdetex/detect-mui/primary-navigation-img/discovery.svg?react';
import DomainsAndWebsitesIcon from '@appdetex/detect-mui/primary-navigation-img/domains-and-websites.svg?react';
import MarketplacesIcon from '@appdetex/detect-mui/primary-navigation-img/marketplaces.svg?react';
import MobileAppsIcon from '@appdetex/detect-mui/primary-navigation-img/mobile-apps.svg?react';
import PaidAdsIcon from '@appdetex/detect-mui/primary-navigation-img/paid-ads.svg?react';
import SocialMediaIcon from '@appdetex/detect-mui/primary-navigation-img/social-media.svg?react';
import uniq from 'lodash/uniq';
import React from 'react';

import store from '../Services/Store';
import { browserHistory } from '../Utils/History';
import { routes } from '../routes';
import domainLogo from './svgComponents/domains.svg';
import marketplacesLogo from './svgComponents/marketplaces.svg';
import mobileLogo from './svgComponents/mobile-apps.svg';
import networksLogo from './svgComponents/networks.svg';
import searchLogo from './svgComponents/search.svg';
import socialLogo from './svgComponents/social.svg';

/**
 * @typedef PortalModule
 * @type {Object}
 * @property {String} displayName
 * @property {Function} [getDisplayName]
 * @property {String} name
 * @property {String} subscriptionType
 * @property {Function} isSubscribed
 * @property {String} viewType
 * @property {String} entity
 * @property {String} productType
 * @property {String} link
 * @property {Boolean} hasSavedViews
 * @property {String} protectedRoute
 * @property {Object} [enforcements]
 * @property {Object} icon
 * @property {Object} primaryNavIcon
 * @property {Boolean} isUniversalSearchEnabled
 * @property {Boolean} supportsGlobalAdvancedSearch
 * @property {Boolean} supportsImport
 * @property {Boolean} [isDisabledByFeatureFlag]
 * @property {Boolean} [isActive]
 */

/**
 * @typedef Subscription
 * @type {Object}
 * @property {Boolean} enf
 * @property {Number} mss
 * @property {String} type
 */

/**
 * @type {PortalModule[]}
 */
const PortalModules = [
  {
    displayName: 'Mobile Apps',
    enforcements: {
      enabled: true,
      enforcementFormName: 'emailForm',
      getLink: (type, uids) => {
        return `/mobile/detections/__enforce/${type}/${uids}/setup`;
      },
      setupFormName: 'emailSetupForm',
      types: [
        {
          label: 'Email Enforcement',
          param: 'email'
        },
        {
          label: 'Outside System',
          param: 'outside'
        },
        {
          label: 'Platform Enforcement',
          param: 'platform'
        }
      ]
    },
    entity: 'APP',
    getDisplayName: () => 'Mobile Apps',
    hasSavedViews: true,
    icon: <img alt="" src={mobileLogo} />,
    isSubscribed: userSubscriptions => userSubscriptions.includes('APP'),
    isUniversalSearchEnabled: true,
    link: routes.APP.detections.path,
    name: 'mobile',
    primaryNavIcon: MobileAppsIcon,
    productType: 'APPS',
    protectedRoute: routes.APP.basePath,
    subscriptionType: 'APP',
    supportsGlobalAdvancedSearch: true,
    supportsImport: true,
    viewType: 'APP'
  },
  {
    displayName: 'Domains & Websites',
    enforcements: {
      enabled: true,
      enforcementFormName: 'domainEmailForm',
      getLink: (type, uids) => {
        return `/domain/detections/__enforce/${type}/${uids}/setup`;
      },
      setupFormName: 'domainSetupForm',
      types: [
        {
          label: 'Domain Enforcement',
          param: 'domain'
        },
        {
          label: 'Website Enforcement',
          param: 'email'
        },
        {
          label: 'Outside System',
          param: 'outside'
        }
      ]
    },
    entity: 'DOMAIN',
    getDisplayName: () =>
      store.getState().adminClients?.domainsAndWebsitesTabText ||
      'Domains & Websites',
    hasSavedViews: true,
    icon: <img alt="" src={domainLogo} />,
    isSubscribed: userSubscriptions =>
      userSubscriptions.includes('DOMAIN') ||
      userSubscriptions.includes('WEBSITES'),
    isUniversalSearchEnabled: true,
    link: routes.DOMAIN.detections.path,
    name: 'domain',
    primaryNavIcon: DomainsAndWebsitesIcon,
    productType: 'DOMAINS',
    protectedRoute: routes.DOMAIN.basePath,
    subscriptionType: 'DOMAIN',
    supportsGlobalAdvancedSearch: true,
    supportsImport: true,
    viewType: 'DOMAIN'
  },
  {
    displayName: 'Paid Ads',
    enforcements: {
      enabled: true,
      enforcementFormName: 'emailForm',
      getLink: (type, uids) => {
        return `/search-engines/detections/__enforce/${type}/${uids}/setup`;
      },
      setupFormName: 'emailSetupForm',
      types: [
        {
          label: 'Email Enforcement',
          param: 'email'
        },
        {
          label: 'Outside System',
          param: 'outside'
        }
      ]
    },
    entity: 'SEARCH_ENGINE',
    getDisplayName: () => 'Paid Ads',
    hasSavedViews: true,
    icon: <img alt="" src={searchLogo} />,
    isSubscribed: userSubscriptions =>
      userSubscriptions.includes('SEARCH_ENGINES'),
    isUniversalSearchEnabled: true,
    link: routes.SEARCH_ENGINE.detections.path,
    name: 'search_engine',
    primaryNavIcon: PaidAdsIcon,
    productType: 'SEARCH_ENGINES',
    protectedRoute: routes.SEARCH_ENGINE.basePath,
    subscriptionType: 'SEARCH_ENGINES',
    supportsGlobalAdvancedSearch: false,
    supportsImport: false,
    viewType: 'SEARCH_ENGINE'
  },
  {
    displayName: 'Marketplaces',
    enforcements: {
      enabled: true,
      enforcementFormName: 'emailForm',
      getLink: (type, uids) => {
        return `/marketplaces/detections/__enforce/${type}/${uids}/setup`;
      },
      setupFormName: 'emailSetupForm',
      types: [
        {
          label: 'Email Enforcement',
          param: 'email'
        },
        {
          label: 'Outside System',
          param: 'outside'
        }
      ]
    },
    entity: 'MARKETPLACES',
    getDisplayName: () => 'Marketplaces',
    hasSavedViews: true,
    icon: <img alt="" src={marketplacesLogo} />,
    isSubscribed: userSubscriptions =>
      userSubscriptions.includes('MARKETPLACES'),
    isUniversalSearchEnabled: true,
    link: routes.MARKETPLACES.detections.path,
    name: 'marketplaces',
    primaryNavIcon: MarketplacesIcon,
    productType: 'MARKETPLACES',
    protectedRoute: routes.MARKETPLACES.basePath,
    subscriptionType: 'MARKETPLACES',
    supportsGlobalAdvancedSearch: false,
    supportsImport: true,
    viewType: 'MARKETPLACES'
  },
  {
    displayName: 'Social Media',
    enforcements: {
      enabled: true,
      enforcementFormName: 'emailForm',
      getLink: (type, uids) => {
        return `/social/detections/__enforce/${type}/${uids}/setup`;
      },
      setupFormName: 'emailSetupForm',
      types: [
        {
          label: 'Platform',
          param: 'platform'
        },
        {
          label: 'Social Enforcement',
          param: 'email'
        },
        {
          label: 'Outside System',
          param: 'outside'
        }
      ]
    },
    entity: 'SOCIAL',
    getDisplayName: () => 'Social Media',
    hasSavedViews: true,
    icon: <img alt="" src={socialLogo} />,
    isSubscribed: userSubscriptions => userSubscriptions.includes('SOCIAL'),
    isUniversalSearchEnabled: true,
    link: routes.SOCIAL.detections.path,
    name: 'social',
    primaryNavIcon: SocialMediaIcon,
    productType: 'SOCIAL',
    protectedRoute: routes.SOCIAL.basePath,
    subscriptionType: 'SOCIAL',
    supportsGlobalAdvancedSearch: false,
    supportsImport: true,
    viewType: 'SOCIAL'
  },
  {
    displayName: 'Tracer Graph',
    entity: 'TRACER',
    getDisplayName: () => 'Tracer Graph',
    hasSavedViews: true,
    icon: <img alt="" src={networksLogo} />,
    isSubscribed: userSubscriptions =>
      userSubscriptions.includes('TRACER') ||
      userSubscriptions.includes('TRACER_EXPLORE'),
    isUniversalSearchEnabled: false,
    link: routes.GRAPH.investigations.path,
    name: 'networks',
    primaryNavIcon: DiscoveryIcon,
    productType: 'TRACER',
    protectedRoute: routes.GRAPH.basePath,
    subscriptionType: 'TRACER',
    supportsGlobalAdvancedSearch: false,
    supportsImport: false,
    viewType: 'INVESTIGATION'
  }
];

/**
 * Get portal products, filtered by `isDisabledByFeatureFlag`
 * @returns {PortalModule[]}
 */
export const getPortalProducts = () =>
  PortalModules.filter(
    portalProduct => portalProduct.isDisabledByFeatureFlag !== true
  );

/**
 * Get product by productName
 * @param {String} productName
 */
export const getPortalProductByName = productName => {
  switch (productName) {
    case 'search-engines':
      return getPortalProducts().find(
        product => product.name === 'search_engine'
      );
    case 'investigation':
    case 'networks':
      return getPortalProducts().find(product => product.name === 'networks');
    case 'websites':
      return getPortalProducts().find(product => product.name === 'domain');
    default:
      return getPortalProducts().find(product => product.name === productName);
  }
};

/**
 * Disable portal product based on feature flag
 * @param {String} productName
 * @param {Boolean} isDisabled
 */
export const setDisabledByFeatureFlagForPortalProductByName = (
  productName,
  isDisabled
) =>
  PortalModules.forEach(product => {
    if (product.name === productName) {
      product.isDisabledByFeatureFlag = isDisabled;
    }
  });

/**
 * Get product by subscriptionType
 * @param {String} subscriptionType
 */
export const getPortalProductBySubscriptionType = subscriptionType =>
  getPortalProducts().find(
    product => product.subscriptionType === subscriptionType
  );

/**
 * Get portal module by URL path
 * @param {String} path
 * @returns {PortalModule} portal module
 */
export const getModuleByPath = path =>
  getPortalProducts().find(product => path.startsWith(product.protectedRoute));

/**
 * Set active module
 * @param {PortalModule} activeModule
 * @returns {void}
 */
export const setActiveModule = activeModule => {
  getPortalProducts().forEach(product => {
    product.isActive = product.name === activeModule.name;
  });
};

/**
 * Get active module
 * @returns {PortalModule} active portal module
 */
export const getActiveModule = () =>
  getPortalProducts().find(product => product.isActive);

/**
 * Get subscribed portal products
 * @param {Subscription[]} subscriptions
 */
export const getSubscribedPortalProducts = subscriptions =>
  subscriptions
    ? getPortalProducts().filter(product =>
        product.isSubscribed(
          subscriptions.map(subscription => subscription.type)
        )
      )
    : null;

/**
 *
 * @param {PortalModule} portalModule
 * @param {Subscription[]} subscriptions
 * @returns {Boolean} is user subscribed to portal module
 */
export const isUserSubscribed = (portalModule, subscriptions) =>
  'subscriptionType' in portalModule &&
  subscriptions
    .map(subscription => subscription.type)
    .includes(portalModule.subscriptionType);

/**
 * Get global advanced search products
 * @param {Subscription[]} subscriptions
 */
export const getGlobalAdvancedSearchProducts = subscriptions =>
  getSubscribedPortalProducts(subscriptions).filter(
    portalProduct => portalProduct.supportsGlobalAdvancedSearch === true
  );

export const getAllEnforcementParamTypes = () =>
  uniq(
    getPortalProducts()
      .filter(product => Array.isArray(product?.enforcements?.types))
      .reduce(
        (enfTypes, currentProduct) => [
          ...enfTypes,
          ...currentProduct.enforcements.types.map(enfType => enfType.param)
        ],
        []
      )
  );

/**
 * TODO named incorrectly
 * @returns {String}
 */
export const getCurrentModuleName = () => {
  const subscriptionType = getActiveModule()?.subscriptionType;
  switch (subscriptionType) {
    case 'TRACER':
      return 'INVESTIGATION';
    case 'SEARCH_ENGINES':
      return 'SEARCH_ENGINE';
    default:
      return subscriptionType;
  }
};

/**
 * TODO this data could just be moved to PortalModules above
 * @returns {String}
 */
export const getCreateLabelAppendText = () => {
  switch (getCurrentModuleName()) {
    case 'APP':
      return 'in Apps';
    case 'DOMAIN':
      return 'in Domains';
    case 'SOCIAL':
      return 'in Social';
    case 'MARKETPLACES':
      return 'in Marketplaces';
    case 'INVESTIGATION':
      return 'in Networks';
    default:
      return 'in your current module';
  }
};

/**
 * TODO Move these API-compatible names to PortalModules,
 *      I don't think we need the toUpperCase functionality
 * @deprecated
 * @param {Boolean} toLower
 * @returns {String}
 */
export const getCurrentAppName = (toLower = false) => {
  let productName = getActiveModule()?.name;
  switch (productName) {
    case 'mobile':
      productName = 'app';
      break;
    case 'networks':
      if (
        browserHistory.location.pathname.includes('/networks/app/detections')
      ) {
        productName = 'tracer';
      } else {
        productName = 'investigation';
      }
      break;
    default:
      break;
  }
  return toLower ? productName : productName?.toUpperCase();
};

/**
 * Returns the subscription type for the user's current tab in enforcement data
 * @returns {String}
 */
export const getSubscriptionTypeByTabIndex = (
  index,
  tabs,
  context,
  isDomainsAndWebsitesSeparate = false
) => {
  switch (index) {
    case 0:
      return '';
    case 1:
      if (context === 'LABELS') {
        return 'ALL';
      }
      return window.location?.search?.includes('EnfData')
        ? 'BRAND'
        : 'all-modules';
    default:
      if (tabs?.[index] === 'SEARCH_ENGINES') {
        return 'SEARCH_ENGINE';
      }
      if (!isDomainsAndWebsitesSeparate) {
        if (
          tabs?.[index] === 'WEBSITES' ||
          tabs?.[index] === 'DOMAIN_AND_WEBSITES'
        ) {
          if (context === 'csv_templates' || context === 'email_templates') {
            return 'WEBSITES';
          } else {
            return 'DOMAIN';
          }
        }
      }
      return tabs?.[index];
  }
};
