export const setUser = user => ({
  payload: user,
  type: 'SET_USER'
});

export const setIsAuthenticated = isAuthenticated => ({
  payload: isAuthenticated,
  type: 'SET_IS_AUTHENTICATED'
});

/**
 * @param {Boolean} isReady
 */
export const setIsReady = isReady => ({
  payload: isReady,
  type: 'SET_IS_READY'
});

export const userFormError = err => ({
  payload: err,
  type: 'USER_FORM_ERROR'
});
