import axios from 'axios';

import { portalConfig } from '../Config/config';

const envUrl = portalConfig.REACT_APP_INFO_URL;

// Paging object we modify
export let paging = {
  number: 0,
  size: 200,
  totalElements: 0,
  totalPages: 0
};

// Do not modify. This is to reset the paging
const defaultPaging = {
  number: 0,
  size: 200,
  totalElements: 0,
  totalPages: 0
};

export const resetPaging = () => {
  // Clone the object, so we don't retain a reference
  paging = JSON.parse(JSON.stringify(defaultPaging));
};

export const storesRequest = () => {
  return axios.get(envUrl + `/api/v1/store/`);
};
