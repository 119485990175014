import { renewUserAndToken } from '../Services/Auth2';
import { isTokenAlmostExpired } from '../Services/BearerToken';
import { initializers } from './initializers';

// Need to get latest account
// Need to lock when changing accounts and vice versa
// Means to show user when a session will end
// On login, can we prevent trying to get saved views, etc. with the wrong account?

const checkToken = () => {
  if (isTokenAlmostExpired()) {
    renewUserAndToken()
      .then(() => window.console.debug('Successfully renewed adx auth token'))
      .catch(window.console.error);
  }
};

const tokenExchangeInitializer = () => {
  const CHECK_INTERVAL = 1000 * 30;
  checkToken();
  setInterval(checkToken, CHECK_INTERVAL);
};

initializers.push(tokenExchangeInitializer);
