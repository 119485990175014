const initialState = {
  allRoles: [],
  userRoles: []
};

const roles = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ROLES':
      return Object.assign({}, state, { allRoles: action.payload.roles });
    case 'SET_USER_ROLES':
      return Object.assign({}, state, { userRoles: action.payload });
    default:
      return state;
  }
};

export default roles;
