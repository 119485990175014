import Bugsnag from '@bugsnag/js';
import axios from 'axios';

import { renewUserAndToken } from '../Services/Auth2';
import { getToken } from '../Services/BearerToken';
import { initializers } from './initializers';

const RETRY_HEADER_KEY = 'x-adx-retried-with-new-token';

const requestNeedsToken = error =>
  error?.response?.status === 401 &&
  error?.config?.url?.includes('appdetex.com') &&
  !error?.config?.headers?.[RETRY_HEADER_KEY];

const axiosInitializer = () => {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (requestNeedsToken(error)) {
        error.config.headers = error.config.headers ?? {};
        error.config.headers[RETRY_HEADER_KEY] = 'yes';
        return renewUserAndToken().then(() => {
          const newToken = getToken();
          if (newToken) {
            error.config.headers.Authorization = `Bearer ${newToken}`;
            return axios(error.config);
          } else {
            delete error.config.headers[RETRY_HEADER_KEY];
            return Promise.reject(error);
          }
        });
      } else {
        return Promise.reject(error);
      }
    }
  );

  axios.interceptors.response.use(
    response => response,
    error => {
      let requestData, errorData;

      if (error?.response) {
        // The request was made and the server responded with a status code not in 2XX range
        if (error?.response?.status === 401) {
          // Invalid access token, will not leave breadcrumb
          return Promise.reject(error);
        }
        requestData = {
          data: error?.response?.config?.data,
          headers: error?.response?.config?.headers,
          method: error?.response?.config?.method,
          url: error?.response?.config?.url
        };
        errorData = error?.response?.data;
      } else {
        requestData = error.toJSON();
        errorData = error.request
          ? 'The request was made, but we received no response'
          : 'There was an error setting up the request';
      }

      Bugsnag.leaveBreadcrumb('Network Request Error', {
        errorData,
        requestData
      });
      return Promise.reject(error);
    }
  );
};

initializers.push(axiosInitializer);
