export const mapModulesAsObjectsForSelect = module => {
  switch (module) {
    case 'APP':
      return {
        label: 'Mobile Apps',
        value: 'APP'
      };
    case 'INVESTIGATION':
      return {
        label: 'Tracer',
        value: 'INVESTIGATION'
      };
    case 'DOMAIN':
      return {
        label: 'Domains',
        value: 'DOMAIN'
      };
    case 'BRAND':
      return {
        label: 'Brands',
        value: 'BRAND'
      };
    case 'SEARCH_ENGINE':
      return {
        label: 'Paid Ads',
        value: 'SEARCH_ENGINE'
      };
    case 'SOCIAL':
      return {
        label: 'Social Media',
        value: 'SOCIAL'
      };
    case 'MARKETPLACES':
      return {
        label: 'Marketplaces',
        value: 'MARKETPLACES'
      };
    default:
      return;
  }
};
