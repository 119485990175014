const notifications = (state = {}, action) => {
  switch (action.type) {
    case 'VERIFICATION_SUCCESS':
      return {
        canResendVerification: false,
        displayText: action.displayText,
        isVerificationRequestSent: true
      };
    case 'VERIFICATION_FAILURE':
      return {
        canResendVerification: true,
        displayText: action.displayText,
        isVerificationRequestSent: true
      };
    case 'RESEND_VERIFICATION_EMAIL_SUCCESS':
      return {
        canResendVerification: false,
        displayText: action.displayText,
        isVerificationRequestSent: true
      };
    case 'RESEND_VERIFICATION_EMAIL_FAILURE':
      return {
        canResendVerification: false,
        displayText: action.displayText,
        isVerificationRequestSent: true
      };
    default:
      return state;
  }
};

export default notifications;
