import axios from 'axios';

import { portalConfig } from '../Config/config';
import {
  getPortalProducts,
  setDisabledByFeatureFlagForPortalProductByName
} from '../Utils/PortalProducts';
import { setCurrentClientFeatureFlags } from '../store/adminClients/adminClients.actions';
import store from './Store';

/**
 * Set feature flags for account
 * @param {Number} accountId
 * @returns
 */
export const setFeatureFlags = (accountId, featureFlags = []) => {
  const currentClientFeatureFlags = {};
  const featureFlagPromises = featureFlags.map(flag =>
    checkFeatureFlag(accountId, flag).then(res => {
      currentClientFeatureFlags[flag] = { enabled: res.data.enabled };
    })
  );
  return Promise.all(featureFlagPromises).then(() =>
    store.dispatch(setCurrentClientFeatureFlags(currentClientFeatureFlags))
  );
};

/**
 * Check if feature flag is enabled for given account
 * @param {Number} accountId
 * @param {String} featureFlag
 * @returns {Promise} API response
 */
export const checkFeatureFlag = (accountId, featureFlag) =>
  axios.get(
    `${portalConfig.REACT_APP_AUTH_URL}/feature-flag/${featureFlag}/${accountId}`
  );

/**
 * Check for feature flags on products and mark as disabled if appropriate
 * @param {Number} accountId
 */
export const disableProductsBasedOnFeatureFlag = accountId =>
  new Promise((resolve, reject) =>
    Promise.all(
      getPortalProducts()
        .filter(portalProduct => portalProduct.featureFlag)
        .map(portalProduct =>
          checkFeatureFlag(accountId, portalProduct.featureFlag).then(
            featureFlagResponse => {
              if (featureFlagResponse?.data?.enabled !== true) {
                setDisabledByFeatureFlagForPortalProductByName(
                  portalProduct.name,
                  true
                );
              }
              return true;
            }
          )
        )
    )
      .then(resolve)
      .catch(reject)
  );
