import axios from 'axios';

import { portalConfig } from '../../../Config/config';
import { Toast as toast } from '../../Toast/Toast';

export class Subscriptions {
  static changeSubscriptions(subscriptions, createLink) {
    if (subscriptions === undefined || !subscriptions.length) {
      return Promise.resolve();
    }

    const updates = subscriptions.filter(
      subscription => !subscription.pendingDelete && !!subscription.id
    );
    const adds = subscriptions.filter(
      subscription => !subscription.pendingDelete && !subscription.id
    );
    const deletes = subscriptions.filter(
      subscription => subscription.pendingDelete
    );

    const subscriptionToPayload = subscription => ({
      accountId: subscription.accountId, // Only present for user subscriptions
      enforcementEnabled:
        subscription.enforcementEnabled?.value ??
        subscription.enforcementEnabled,
      maxSavedSearches: subscription.maxSavedSearches,
      subscriptionEnabled:
        subscription.subscriptionEnabled?.value ??
        subscription.subscriptionEnabled,
      subscriptionType:
        subscription.subscriptionType?.value ?? subscription.subscriptionType
    });

    const addProms = adds.map(subscription =>
      axios.post(createLink, subscriptionToPayload(subscription))
    );

    const updateProms = updates.map(subscription =>
      axios.put(
        subscription._links.self.href,
        subscriptionToPayload(subscription)
      )
    );

    const deleteProms = deletes.map(subscription =>
      axios.delete(subscription._links.self.href)
    );

    const allPromises = deleteProms.concat(updateProms).concat(addProms);

    return axios.all(allPromises).catch(err => {
      if (err.response && err.response.data) {
        toast.error(
          `There was an error trying to subscribe: ${
            err.response.data.message &&
            err.response.data.message.includes(
              'already has subscription of type'
            )
              ? 'Only one subscription of each subscription type is allowed'
              : err.response.data.message
          }`
        );
      } else {
        toast.error('There was an unknown error trying to subscribe');
      }
      return err;
    });
  }

  static getSubscriptionOptions() {
    return axios.get(`${portalConfig.REACT_APP_AUTH_URL}/subscriptions`);
  }

  // This feels wrong. Probably a better way to do this. But we pass models into the redux form, and then IF someone
  // messed with a particular field, it gets mutated into some redux-form state that is different from how it started.
  // If it was messed with, pull the value out and use it. Otherwise, keep it what it was
  static parseSubscriptionsFromForm(subscriptions) {
    return subscriptions.map(sub => {
      const newSub = Object.assign({}, sub);
      newSub.enforcementEnabled =
        sub.enforcementEnabled.value ?? sub.enforcementEnabled;
      newSub.subscriptionEnabled =
        sub.subscriptionEnabled.value ?? sub.subscriptionEnabled;
      newSub.subscriptionType =
        sub.subscriptionType.value ?? sub.subscriptionType;
      newSub.maxSavedSearches = parseInt(sub.maxSavedSearches, 10);
      newSub.accountId =
        (sub.accountId && sub.accountId.value) ?? sub.accountId; // Only actually present in the user-level subscription

      return newSub;
    });
  }
}
