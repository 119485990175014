const initialState = {
  areAllDetectionsSelected: false,
  selected: []
};

const filteredTable = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_SELECTED':
      return Object.assign({}, state, {
        selected: [...state.selected, action.payload]
      });
    case 'REMOVE_SELECTED':
      state.selected.splice(state.selected.indexOf(action.payload), 1);
      return Object.assign({}, state, { selected: state.selected });
    case 'RESET_SELECTED':
      return Object.assign({}, state, { selected: [] });
    case 'SET_SELECTED':
      return Object.assign({}, state, { selected: action.payload });
    case 'SET_SELECT_ALL_IN_VIEW':
      return Object.assign({}, state, {
        areAllDetectionsSelected: action.payload
      });
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default filteredTable;
