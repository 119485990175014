const testCommentData = {
  comments: {
    commentsMessage: 'No comments have been added for this app yet.'
  },
  commentsLinks: ''
};

const activityPanel = (state = testCommentData, action) => {
  switch (action.type) {
    case 'POPULATE_COMMENTS':
      if (action.payload) {
        return Object.assign({}, state, {
          comments: action.payload._embedded,
          commentsLinks: action.payload._links
        });
      }
      return state;
    case 'COMMENTS_ERROR':
      const errMsg = Object.assign({}, state.comments, {
        commentsMessage:
          'Unable to retrieve comments at this time: Error: ' + action.payload
      });
      return Object.assign({}, state, {
        comments: errMsg
      });
    default:
      return state;
  }
};

export default activityPanel;
