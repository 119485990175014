import axios from 'axios';
import { createBrowserHistory } from 'history';

import { portalConfig } from '../../Config/config';
import { setAccountSwitchedTo } from '../../Initializers/account-switched-initializer';
import AppdetexAuth from '../../Services/Auth';
import { renewUserAndToken } from '../../Services/Auth2';
import store from '../../Services/Store';
import { getUserDestination } from '../../Services/User';
import { Subscriptions } from '../../Shared/Admin/Subscriptions/Subscriptions';
import { Toast as toast } from '../../Shared/Toast/Toast';
import { setColumns, setFilters } from '../detections/detections.actions';
import { setAllSavedViews } from '../savedViews/savedViews.actions';

export const getAccounts = (url, searchString) => {
  return dispatch => {
    dispatch(setIsLoadingAccounts());
    return AppdetexAuth.getAccounts(url, searchString)
      .then(res => {
        return dispatch(setAccounts(res.data));
      })
      .catch(() => {
        return dispatch(setAccounts([]));
      });
  };
};

export const switchAccounts = (newAccount, goToDashboard) => {
  return dispatch => {
    return renewUserAndToken(newAccount.accountId).then(() => {
      dispatch(setColumns([]));
      dispatch(setFilters([]));
      dispatch(setAllSavedViews(null));
      window.localStorage.removeItem('scrollData');
      if (goToDashboard) {
        setAccountSwitchedTo(newAccount.accountName);
        const history = createBrowserHistory({
          forceRefresh: true
        });
        const { pathname } = history.location;
        if (pathname.includes('enforce')) {
          // TODO use component from detect-ui
          const confirm = window.confirm(
            'You are in the middle of an enforcement. Are you sure you would like to leave? All current progress will be lost.'
          );
          if (confirm) {
            history.push(
              pathname.slice(
                0,
                pathname.includes('__enforce')
                  ? pathname.indexOf('/__enforce')
                  : pathname.indexOf('/enforce')
              )
            );
          }
        } else {
          try {
            return history.push(getUserDestination());
          } catch (err) {
            toast.error(err.message);
            return history.push('/');
          }
        }
      }
    });
  };
};

export const addAccount = (account, client) => {
  const url = `${portalConfig.REACT_APP_AUTH_URL}/accounts/clients/${
    client ? client.id : AppdetexAuth.getCurrentAccount().clientId
  }`;
  return dispatch => {
    return axios
      .post(url, account)
      .then(res => {
        const createSubscriptionLink = res.data._links.createSubscription.href;
        return Subscriptions.changeSubscriptions(
          account.subscriptions,
          createSubscriptionLink
        ).then(() => {
          toast.success(`Successfully created account: ${account.name}`);
          dispatch(addAccountFormError(null));
          return res;
        });
      })
      .catch(err => {
        toast.error(`Error creating account: ${account.name}`);
        dispatch(addAccountFormError(err.response.data));
        throw err;
      });
  };
};

export const editAccount = account => {
  return () => {
    return axios
      .put(account._links.self.href, account)
      .then(res => {
        const createSubscriptionLink = account._links.createSubscription?.href;
        // If we aren't a super admin we won't have access to this link
        if (!createSubscriptionLink) {
          toast.success(`Successfully updated account: ${account.name}`);
          return res;
        }

        return Subscriptions.changeSubscriptions(
          account.subscriptions,
          createSubscriptionLink
        )
          .then(() => {
            // Refresh our token to make sure we have the latest subscription information, if we edited our active account
            if (AppdetexAuth.getCurrentAccount().accountId === account.id) {
              renewUserAndToken(account.id);
            }

            toast.success(`Successfully updated account: ${account.name}`);
            return res;
          })
          .catch(err => {
            console.error(err);
            toast.error(
              `Failed updating account subscriptions for account: ${account.name}`
            );
            return res;
          });
      })
      .catch(err => {
        toast.error(`Error updating account: ${account.name}`);
        console.error(err);
        return err;
      });
  };
};

export const deleteAccount = account => {
  return () => {
    return axios
      .delete(account._links.self.href)
      .then(res => {
        toast.success(`Successfully deleted account: ${account.name}`);
        return res;
      })
      .catch(err => {
        const message = err?.response?.data?.message ?? '';
        toast.error(`Error deleting account: ${account.name}. ${message}`);
        return err;
      });
  };
};

export const deactivateAccount = account => {
  return () => {
    return axios
      .patch(`${account._links.self.href}/deactivate`)
      .then(res => {
        return res;
      })
      .catch(err => {
        toast.error(
          `Error deactivating account: ${account.name}. ${
            err?.response?.data?.message ?? ''
          }`
        );
        return err;
      });
  };
};

export const activateAccount = account => {
  return () => {
    return axios
      .patch(`${account._links.self.href}/activate`)
      .then(res => {
        return res;
      })
      .catch(err => {
        toast.error(
          `Error activating account: ${account.name}. ${
            err?.response?.data?.message ?? ''
          }`
        );
        return err;
      });
  };
};

export const selectAccount = account => {
  return dispatch => {
    if (account) {
      return axios
        .get(
          `${portalConfig.REACT_APP_AUTH_URL}/accounts/${
            account.accountId || account.id
          }`
        )
        .then(res => {
          return dispatch(selectedAccount(res.data));
        })
        .catch(() => {
          return dispatch(selectedAccount(undefined));
        });
    } else {
      return dispatch(selectedAccount(account));
    }
  };
};

export const changePage = (verb, pageNumber) => {
  const links = store.getState().accounts.accounts._links;
  let url;
  if (verb) {
    url = links?.[verb]?.href || links.self.href;
  } else {
    url = links.self.href;
    if (url.includes('page')) {
      url = url.replace(/page=\d+/, `page=${pageNumber}`);
    } else {
      url = `${url}${url.includes('?') ? '&' : '?'}page=${pageNumber}`;
    }
  }
  return dispatch => {
    dispatch(setIsLoadingAccounts());
    return AppdetexAuth.getAccounts(url).then(res => {
      return dispatch(setAccounts(res.data));
    });
  };
};

export const getAccountSubscriptions = account => {
  return dispatch => {
    if (account === undefined) {
      return dispatch(setSelectedAccountSubscriptions([]));
    }

    const subscriptionLink = account._links
      ? account._links.getSubscriptions.href
      : `${portalConfig.REACT_APP_AUTH_URL}/subscriptions/account/${
          account.accountId || account.id
        }`;
    return axios
      .get(subscriptionLink)
      .then(res => {
        return dispatch(
          setSelectedAccountSubscriptions(
            res.data?._embedded?.subscriptions ?? []
          )
        );
      })
      .catch(err => {
        console.warn('Unable to retrieve account subscriptions', err);
      });
  };
};

export const getCurrentClient = userId => {
  return dispatch => {
    return AppdetexAuth.getUserClients(userId)
      .then(res => {
        dispatch(setCurrentClient(res.data));
        return res;
      })
      .catch(() => {
        dispatch(setCurrentClient());
      });
  };
};

export const getIndustries = () => {
  return axios
    .get(`${portalConfig.REACT_APP_AUTH_URL}/accounts/industries`)
    .then(res => {
      return res.data;
    });
};

export const setCurrentClient = client => {
  return {
    payload: client,
    type: 'SET_CURRENT_CLIENT'
  };
};

export const selectedAccount = account => {
  if (account && account._embedded?.credentials) {
    const { credentials } = account._embedded;
    for (const cred of credentials) {
      if (cred.credentialType === 'AMAZON') {
        account.amazonCredentials = cred;
      }
    }
  }
  return {
    payload: account,
    type: 'SELECT_ACCOUNT'
  };
};

export const setUserAccounts = accounts => {
  return {
    payload: accounts,
    type: 'SET_USER_ACCOUNTS'
  };
};

export const setAccounts = accounts => {
  return {
    payload: accounts,
    type: 'SET_ACCOUNTS'
  };
};

export const setSelectedAccountSubscriptions = subscriptions => {
  return {
    payload: subscriptions,
    type: 'SET_SELECTED_ACCOUNT_SUBSCRIPTIONS'
  };
};

export const setCurrentAccount = account => {
  return {
    payload: account,
    type: 'SET_CURRENT_ACCOUNT'
  };
};

export const addAccountFormError = err => {
  return {
    payload: err,
    type: 'ADD_ACCOUNT_FORM_ERROR'
  };
};

export const setIsLoadingAccounts = () => {
  return {
    type: 'SET_IS_LOADING_ACCOUNTS'
  };
};
