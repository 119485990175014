import { areWeTesting } from '../Utils/are-we-testing';
import { developmentConfig } from './development-config';
import { getHostnameForConfig } from './get-hostname-for-config.js';
import { productionConfig } from './production-config';
import { testConfig } from './test-config';

let cfg;

switch (getHostnameForConfig()) {
  case 'portal.appdetex.com':
  case 'portal-2.appdetex.com':
  case 'portal.tracer.ai':
  case 'portal-beta.tracer.ai':
    cfg = productionConfig;
    break;
  case 'portal.test-cloud.appdetex.com':
  case 'portal-3.test-cloud.appdetex.com':
  case 'portal.test-cloud.tracer.ai':
  case 'portal-2.test-cloud.appdetex.com':
  case 'portal-2.test-cloud.tracer.ai':
  case 'portal-feature.test-cloud.tracer.ai':
    cfg = testConfig;
    break;
  case 'localhost':
  case 'portal.localdev':
  case 'portal.internal':
    cfg = developmentConfig;
    break;
  default:
    break;
}

if (areWeTesting) {
  cfg = developmentConfig;
}

export const portalConfig = cfg;
