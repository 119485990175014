const currencyData = {
  currencies: []
};

const currency = (state = currencyData, action) => {
  switch (action.type) {
    case 'SET_CURRENCIES':
      return Object.assign({}, state, {
        currencies: action.payload,
        preferredCurrency: action.payload.find(currency => currency.preferred)
          ?.currency
      });
    case 'SET_ORIGINAL':
      return Object.assign({}, state, {
        preferredCurrency: undefined
      });
    case 'SET_PREFERRED':
      return Object.assign({}, state, {
        preferredCurrency: action.payload.currency
      });
    default:
      return state;
  }
};

// disabling eslint no default export as this refactor is sizeable
// eslint-disable-next-line import/no-default-export
export default currency;
