const permissions = (state = { case: {}, retrieved: false }, action) => {
  switch (action.type) {
    case 'ADD_PERMISSIONS':
      return Object.assign({}, state, {
        retrieved: true,
        retrieving: false,
        ...action.payload
      });
    case 'ADD_CASE_PERMISSIONS':
      return Object.assign({}, state, {
        case: Object.assign({}, action.payload, { retrieved: true })
      });
    case 'SET_PERMISSIONS':
      return Object.assign(
        {},
        { retrieved: true, retrieving: false, ...action.payload }
      );
    default:
      return state;
  }
};

export default permissions;
