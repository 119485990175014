import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';

import store from '../Services/Store';
import { ComponentLoading } from '../Shared/ComponentLoading/ComponentLoading';
import { Toast as toast } from '../Shared/Toast/Toast';
import { getPortalProducts } from './PortalProducts';

/**
 * Renders the component if authorized, else redirect to LoginContainer
 */
export class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: null,
      isLoading: true,
      isSubscribed: null
    };
  }

  checkAuthorization() {
    const subscriptions =
      store.getState().adminClients.currentClientSubscriptions;

    const isAuthenticated = store.getState().user.isAuthenticated;

    const targetProduct = getPortalProducts().find(product =>
      this.props.location.pathname.startsWith(product.protectedRoute)
    );

    const isSubscribed =
      this.props.ignoreSubscription ||
      (targetProduct &&
        targetProduct.isSubscribed(
          subscriptions.map(subscription => subscription.type)
        ));

    if (isAuthenticated && !isSubscribed) {
      toast.warning(
        `User is not subscribed to ${
          targetProduct?.displayName
            ? targetProduct.displayName
            : 'requested module'
        }.`,
        {
          toastId: 'USER_NOT_SUBSCRIBED_TO_MODULE'
        }
      );
    }

    this.setState({
      isAuthenticated,
      isLoading: false,
      isSubscribed
    });
  }

  componentDidMount() {
    if (store.getState().user.isReady) {
      this.checkAuthorization();
    } else {
      const unbindStoreListener = store.subscribe(() => {
        if (store.getState().user.isReady) {
          this.checkAuthorization();
          unbindStoreListener();
        }
      });
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props => {
          if (this.state.isLoading) {
            return <ComponentLoading />;
          } else if (this.state.isAuthenticated && this.state.isSubscribed) {
            return <Component {...props} />;
          } else {
            // TODO can we conditionally redirect to the SSO login page?
            return <Redirect to="/login" />;
          }
        }}
      />
    );
  }
}
