const initialState = {
  enforcementSettings: [],
  isLoadingEnforcementSettings: false,
  isLoadingSelectedCredential: false,
  selectedCredential: {
    settings: {
      fields: []
    }
  }
};

const platformCredentials = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'SET_ENFORCEMENT_SETTINGS':
      return Object.assign({}, state, { enforcementSettings: payload });
    case 'SET_SELECTED_ENFORCEMENT_SETTING':
      return Object.assign({}, state, { selectedCredential: payload });
    case 'SET_IS_LOADING_ENFORCEMENT_SETTINGS':
      return Object.assign({}, state, {
        isLoadingEnforcementSettings: payload
      });
    case 'SET_IS_LOADING_SELECTED_CREDENTIAL':
      return Object.assign({}, state, { isLoadingSelectedCredential: payload });
    default:
      return state;
  }
};

export default platformCredentials;
