const initialState = {
  formErrors: undefined,
  isLoading: false,
  selectedUser: undefined,
  userAccounts: [],
  userClients: [],
  users: []
};

const adminUsers = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ADMIN_USERS':
      return Object.assign({}, state, {
        isLoading: false,
        users: action.payload
      });
    case 'SET_IS_LOADING_ADMIN_USERS':
      return Object.assign({}, state, { isLoading: true });
    case 'SELECT_USER':
      return Object.assign({}, state, { selectedUser: action.payload });
    case 'SET_SELECTED_USER_SUBSCRIPTIONS':
      return Object.assign({}, state, {
        selectedUserSubscriptions: action.payload
      });
    case 'SET_FORM_ERRORS':
      const errorObj = {};
      if (
        action.payload &&
        action.payload.errors &&
        action.payload.errors.length
      )
        action.payload.errors.map(error => {
          return (errorObj[error.field] =
            error.defaultMessage.charAt(0).toUpperCase() +
            error.defaultMessage.substring(1));
        });
      return Object.assign({}, state, { formErrors: errorObj });
    case 'SET_USER_ACCOUNTS':
      return Object.assign({}, state, { userAccounts: action.payload });
    case 'SET_USER_CLIENTS':
      return Object.assign({}, state, { userClients: action.payload });
    default:
      return state;
  }
};

export default adminUsers;
