import { isEqual } from 'lodash';
import { Userpilot } from 'userpilot';

import { getHostnameForConfig } from '../Config/get-hostname-for-config';
import store from '../Services/Store';
import { initializers } from './initializers';

const userPilotInitializer = () => {
  if (
    getHostnameForConfig() !== 'portal-feature.test-cloud.tracer.ai' &&
    getHostnameForConfig() !== 'portal-beta.tracer.ai' &&
    getHostnameForConfig() !== 'portal.tracer.ai'
  ) {
    return;
  }

  Userpilot.initialize('NX-f8b9d9bc');

  let user;
  store.subscribe(() => {
    const previousUser = user;
    user = store.getState()?.user;

    if (!isEqual(previousUser, user) && user.isInitialized === true) {
      const user = store.getState().user;
      const currentAccount = store.getState().accounts.currentAccount;
      Userpilot.identify(user.id, {
        created_at: user.createdOn,
        email: user.email,
        name: user.firstName
      });
      if (!user || !currentAccount) {
        throw new Error(
          'Unable to send userpilot metrics. Missing user or account.'
        );
      }
    }
  });
};

initializers.push(userPilotInitializer);
