const initialState = {
  isCreatingBrandSettings: false,
  isLoading: false
};
export const aiSettings = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return Object.assign({}, state, {
        isLoading: action.payload
      });
    case 'SET_IS_CREATING_BRAND_SETTINGS':
      return Object.assign({}, state, {
        isCreatingBrandSettings: action.payload
      });
    case 'SET_BRAND_AI_SETTINGS':
      return Object.assign({}, state, {
        brandAiSettings: action.payload
      });
    case 'SET_ACCOUNT_AI_SETTINGS':
      return Object.assign({}, state, {
        accountAiSettings: action.payload
      });
    case 'UPDATE_LOCAL_BRAND_AI_SETTINGS':
      return Object.assign({}, state, {
        brandAiSettings: state.brandAiSettings.map(aiSetting =>
          aiSetting.id === action.payload.brandId
            ? {
                ...aiSetting,
                closedReason: [...aiSetting.closedReason, action.payload],
                isBrandAiEnabled: true
              }
            : aiSetting
        )
      });
    case 'TOGGLE_LOCAL_BRAND_AI_SETTINGS':
      return Object.assign({}, state, {
        brandAiSettings: state.brandAiSettings.map(aiSetting =>
          aiSetting.id === action.payload.id
            ? {
                ...aiSetting,
                closedReason: [],
                isBrandAiEnabled: !aiSetting.isBrandAiEnabled
              }
            : aiSetting
        )
      });
    default:
      return state;
  }
};
