import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { areWeTesting } from '../Utils/are-we-testing';
import { rootReducers } from '../store/index.reducer';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducers,
  !areWeTesting && window.__REDUX_DEVTOOLS_EXTENSION__
    ? compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__())
    : compose(applyMiddleware(thunk))
);

// eslint-disable-next-line import/no-default-export
export default store;
