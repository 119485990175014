const initState = {
  allLabels: {},
  currLabels: {},
  links: {}
};
const labels = (state = initState, action) => {
  switch (action.type) {
    case 'SET_LABELS':
      return Object.assign({}, state, {
        allLabels: action.payload._embedded ? action.payload._embedded : [],
        links: action.payload._links
      });
    case 'SET_CURRENT_LABELS':
      return Object.assign({}, state, {
        currLabels: action.payload
      });
    case 'CLEAR_CURRENT_LABELS':
      return Object.assign({}, state, {
        currLabels: { labels: [] }
      });
    default:
      return state;
  }
};

export default labels;
