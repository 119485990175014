const initialState = {
  domainSearchFields: [],
  socialSearchFields: []
};

const advancedSearch = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DOMAIN_SEARCH_FIELDS':
      return Object.assign({}, state, { domainSearchFields: action.payload });
    case 'SET_SOCIAL_SEARCH_FIELDS':
      return Object.assign({}, state, { socialSearchFields: action.payload });
    default:
      return state;
  }
};

export default advancedSearch;
