import { Auth } from 'aws-amplify';
import axios from 'axios';
import { reset } from 'redux-form';

import { portalConfig } from '../Config/config';
import { Toast as toast } from '../Shared/Toast/Toast';
import { browserHistory } from '../Utils/History';
import { assignAuthToWindowForCypress } from '../Utils/assign-auth-to-window-for-cypress';
import { setIsAuthenticated, setIsReady } from '../store/user/user.actions';
import { clearToken, getAccountIdFromToken, setToken } from './BearerToken';
import {
  clearUserNotFound,
  isSingleSignOnUser,
  setUserNotFound,
  storeSsoSignInPath
} from './SingleSignOn';
import store from './Store';
import { getUser, storeExportPath } from './User';
/**
 * Get current cognito user session
 * @returns {Object} CognitoUserSession
 */
export const getSession = () =>
  Auth.currentSession().then(session => {
    if (session.isValid()) {
      return session;
    } else {
      throw new Error('No current user');
    }
  });

/**
 * Check if we have a valid current session
 * @returns {Promise} resolved if authenticated, rejected if not
 */
export const isAuthenticated = () => getSession().then(() => true);

/**
 * Get the Cognito identity token
 * @returns {Promise} resolves with JWT token
 */
export const getCognitoJwtToken = () =>
  getSession().then(session => session.getIdToken().getJwtToken());

/**
 * Call token/exchange endpoint
 * @param {Object} token cognito id token
 * @param {Number|String} [accountId]
 * @returns {Promise} /token/exchange endpoint response
 */
const exchangeToken = (token, accountId) =>
  axios({
    data: {
      accountId: accountId ?? getCurrentAccountId(),
      token
    },
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    transformRequest: (data, headers) => {
      if (headers.common['Authorization']) {
        delete headers.common['Authorization'];
      }
      return JSON.stringify(data);
    },
    url: `${portalConfig.REACT_APP_AUTH_URL}/token/exchange`
  });

/**
 * Exchange a Cognito identity token for an Appdetex Auth token. Also,
 * call get user and set user statuses in redux store.
 * @param {Object} token
 * @param {Number|String} [accountId]
 * @returns {Promise} resolves after token has been exchanged and /users/me endpoint
 * has been called.
 */
export const exchangeCognitoJwtForAppdetexJwt = (token, accountId) => {
  clearUserNotFound();

  return exchangeToken(token, accountId)
    .then(({ data }) => {
      setToken(data.accessToken);
      return true;
    })
    .then(() => {
      return store.dispatch(setIsAuthenticated(true));
    })
    .then(getUser)
    .catch(error => {
      if (error?.response?.status !== 404) {
        return Promise.reject(error);
      } else {
        return isSingleSignOnUser().then(isSingleSignOnUserResponse => {
          clearStoredCurrentAccountId();
          clearToken();
          // If this is a single sign on user who hasn't yet been onboarded,
          // redirect them to SingleSignOnErrorNotFound
          if (isSingleSignOnUserResponse) {
            setUserNotFound();
            return Auth.signOut();
          } else if (!isSingleSignOnUserResponse) {
            return Promise.reject(
              'Authentication failed. User could not be found in the current account. Try again.'
            );
          }
        });
      }
    })
    .finally(() => store.dispatch(setIsReady(true)));
};

/**
 * Get the Cognito identity token and exchange it for an Appdetex Auth token,
 * sign out user if we can't renew.
 * TODO evaluate if we want to sign out here.
 * @param {Number|String} [accountId]
 * @returns {Promise}
 */
export const renewUserAndToken = accountId =>
  getCognitoJwtToken()
    .then(token => exchangeCognitoJwtForAppdetexJwt(token, accountId))
    // TODO does this need to toast?
    .catch(() => signOut());

/**
 * Sign out a user, optionally specify where to sign out to and
 * additionally optionally specify where to return them to after
 * they re-authenticate.
 * @returns {Promise}
 */
export const signOut = () => {
  const { pathname, search } = window.location;

  // If the user was attempting to download an export from an email,
  // temporarily store the path + search so we can redirect them
  // after they log in.
  if (pathname === '/export') {
    storeExportPath(`${pathname}${search}`);
    // Extract the ssoPath from the search params if it exists
    const searchParams = new URLSearchParams(search);
    if (searchParams.has('ssoPath')) {
      storeSsoSignInPath(`/sso/${searchParams.get('ssoPath')}`);
    }
  }

  clearToken();
  store.dispatch(setIsAuthenticated(false));
  store.dispatch(setIsReady(true));

  return (
    Auth.signOut()
      // This finally block only runs for non-SSO users.
      // SSO users get logged out by Auth#signOut and this
      // doesn't have a chance to run.
      .finally(() => browserHistory.push('/login'))
  );
};

/**
 * Storage key for current accountId
 * @type {String}
 */
const CURRENT_ACCOUNT_ID_KEY = 'adxCurrentAccountId';

/**
 * Stores most recent accountId in localStorage
 * @param {Number} accountId
 * @returns {void}
 */
export const storeCurrentAccountId = accountId =>
  window.localStorage.setItem(CURRENT_ACCOUNT_ID_KEY, accountId.toString());

/**
 * Removes most recent accountId from localStorage
 * @returns {void}
 */
export const clearStoredCurrentAccountId = () =>
  window.localStorage.removeItem(CURRENT_ACCOUNT_ID_KEY);

/**
 * Get the current accountId. Check in order:
 * 1. from the Appdetex Auth token
 * 2. localStorage
 * @returns {(Number|null)} accountId or null
 */
export const getCurrentAccountId = () => {
  const accountIdFromToken = getAccountIdFromToken();
  const accountIdFromStorage = parseInt(
    window.localStorage.getItem(CURRENT_ACCOUNT_ID_KEY),
    10
  );

  if (accountIdFromToken) {
    return accountIdFromToken;
  } else if (!isNaN(accountIdFromStorage)) {
    return accountIdFromStorage;
  } else {
    return null;
  }
};

/**
 * Change a user password
 * @param {Object} formSubmission
 * @param {String} formSubmission.currentPassword
 * @param {String} formSubmission.newPassword
 * @returns {Promise} API response
 */
export const changePassword = ({ currentPassword, newPassword }) =>
  getSession().then(session => {
    const accessToken = session?.accessToken?.jwtToken;

    return axios
      .put(
        `${portalConfig.REACT_APP_AUTH_URL}/users/${
          store.getState().user.id
        }/changePassword`,
        {
          accessToken,
          previousPassword: currentPassword,
          proposedPassword: newPassword
        }
      )
      .then(res => {
        store.dispatch(reset('changePassword'));
        toast.success('Your password was successfully changed.');
        return res;
      })
      .catch(error => {
        // FIXME this doesn't currently work. You just get a 500.
        if (
          error?.response?.data?.message?.includes(
            'Incorrect username or password'
          )
        ) {
          toast.error('Your current password is incorrect.');
        } else {
          store.dispatch(reset('changePassword'));
          toast.error(
            'An unexpected error occurred while trying to change your password. Check that your current password is correct and try again.'
          );
        }
      });
  });

assignAuthToWindowForCypress(Auth, getCognitoJwtToken, exchangeToken, signOut);
