import axios from 'axios';
import has from 'has';

import { portalConfig } from '../../Config/config';
import AppdetexAuth from '../../Services/Auth';
import store from '../../Services/Store';
import { Toast as toast } from '../../Shared/Toast/Toast';

export const getClients = (url, searchString) => {
  return dispatch => {
    dispatch(setIsLoadingAdminClients());
    return AppdetexAuth.getClients(url, searchString)
      .then(res => {
        return dispatch(setClients(res.data));
      })
      .catch(() => {
        return dispatch(setClients([]));
      });
  };
};

export const getSelfManagedClients = () => dispatch => {
  dispatch(setIsLoadingSelfManagedClients(true));
  axios
    .get(`${portalConfig.REACT_APP_AUTH_URL}/clients/?selfManaged=true`)
    .then(res => {
      return dispatch(setSelfManagedClients(res.data?._embedded?.clients));
    })
    .finally(() => {
      dispatch(setIsLoadingSelfManagedClients(false));
    });
};

export const getClient = id => {
  return dispatch => {
    return AppdetexAuth.getClient(id)
      .then(res => {
        return dispatch(setClient(res.data));
      })
      .catch(() => {
        return dispatch(setClient(null));
      });
  };
};

export const addClient = client => {
  return dispatch => {
    return AppdetexAuth.saveClient(client)
      .then(res => {
        toast.success('Saved New Client');
        dispatch(addClientFormError(null));
        return res;
      })
      .catch(err => {
        toast.error('Failed To Create New Client');
        dispatch(addClientFormError(err.response.data));
        throw err;
      });
  };
};

export const editClient = (url, client, isActive) => {
  return dispatch => {
    return AppdetexAuth.editClient(url, client, isActive)
      .then(() => {
        dispatch(addClientFormError(null));
        toast.success('Updated');
        // Refresh page to render updated client
        return dispatch(changePage('self'));
      })
      .catch(err => {
        toast.error('Failed To Update Client');
        const message = err.response ? err.response.data : {};
        dispatch(addClientFormError(message));
        throw err;
      });
  };
};

export const nullifySelectedClient = () => {
  return dispatch => {
    return dispatch(setClient(null));
  };
};

export const changePage = (verb, pageNumber) => {
  const links = store.getState().adminClients.clients._links;
  let url;
  if (verb) {
    url = has(links, verb) ? links[verb].href : links.self.href;
  } else {
    url = links.self.href;
    if (url.includes('page')) {
      url = url.replace(/page=\d+/, `page=${pageNumber}`);
    } else {
      url = `${url}${url.includes('?') ? '&' : '?'}page=${pageNumber}`;
    }
  }

  return dispatch => {
    dispatch(setIsLoadingAdminClients());
    return AppdetexAuth.getClients(url).then(res => {
      return dispatch(setClients(res.data));
    });
  };
};

export const getClientSubscriptions = client => {
  return dispatch => {
    const subscriptionLink = client._links.getSubscriptions.href;

    return axios
      .get(subscriptionLink)
      .then(res => {
        dispatch(setClientSubscriptions(res.data));
      })
      .catch(err =>
        console.warn('Unable to retrieve client subscriptions', err)
      );
  };
};

export const setDomainsAndWebsitesTabText = text => {
  return {
    payload: text,
    type: 'SET_DOMAINS_AND_WEBSITES_TAB_TEXT'
  };
};

export const addClientFormError = err => {
  return {
    payload: err,
    type: 'ADD_CLIENT_FORM_ERROR'
  };
};

export const setIsLoadingAdminClients = () => {
  return {
    type: 'SET_IS_LOADING_ADMIN_CLIENTS'
  };
};

export const setClients = data => {
  return {
    payload: data,
    type: 'SET_ALL_CLIENTS'
  };
};

export const setSelfManagedClients = data => {
  return {
    payload: data,
    type: 'SET_SELF_MANAGED_CLIENTS'
  };
};

export const setIsLoadingSelfManagedClients = data => {
  return {
    payload: data,
    type: 'SET_IS_LOADING_SELF_MANAGED_CLIENTS'
  };
};

export const setClient = data => {
  return {
    payload: data,
    type: 'SET_SELECTED_CLIENT'
  };
};

export const setClientSubscriptions = subscriptions => {
  return {
    payload: subscriptions,
    type: 'SET_SELECTED_CLIENT_SUBSCRIPTIONS'
  };
};

export const setCurrentClientSubscription = subscription => {
  return {
    payload: subscription,
    type: 'SET_CURRENT_CLIENT_SUBSCRIPTION'
  };
};

export const setCurrentClientSubscriptions = subscriptions => {
  return {
    payload: subscriptions,
    type: 'SET_CURRENT_CLIENT_SUBSCRIPTIONS'
  };
};

export const setCurrentClientFeatureFlags = featureFlag => {
  return {
    payload: featureFlag,
    type: 'SET_CURRENT_CLIENT_FEATURE_FLAGS'
  };
};
