export const getDomainsAndWebsitesTabText = subscriptions => {
  const hasDomainsSubscription = subscriptions?.find(
    sub => sub.type === 'DOMAIN'
  );
  const hasWebsitesSubscription = subscriptions?.find(
    sub => sub.type === 'WEBSITES'
  );

  if (hasDomainsSubscription && hasWebsitesSubscription) {
    return 'Domains & Websites';
  } else if (hasDomainsSubscription && !hasWebsitesSubscription) {
    return 'Domains';
  } else if (!hasDomainsSubscription && hasWebsitesSubscription) {
    return 'Websites';
  }
};
