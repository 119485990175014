/* eslint-disable import/no-default-export */
import has from 'has';

const initial = {
  clients: {},
  currentClientFeatureFlags: {},
  currentClientSubscriptions: [],
  errors: '',
  isLoading: false,
  selectedClient: {},
  selectedClientSubscriptionOptions: [],
  selectedClientSubscriptions: {},
  success: undefined
};
export const adminClients = (state = initial, action) => {
  switch (action.type) {
    case 'SET_DOMAINS_AND_WEBSITES_TAB_TEXT':
      return Object.assign({}, state, {
        domainsAndWebsitesTabText: action.payload
      });
    case 'ADD_CLIENT_FORM_ERROR':
      let errors = '';
      if (action.payload) {
        errors = has(has(action.payload, 'errors'))
          ? action.payload.errors
              .map(val => {
                return `${val.field} - ${val.defaultMessage}`;
              })
              .join('<br />')
          : action.payload.error;
      }
      return Object.assign({}, state, { errors });
    case 'SET_ALL_CLIENTS':
      return Object.assign({}, state, {
        clients: action.payload,
        isLoading: false
      });
    case 'SET_SELF_MANAGED_CLIENTS':
      return Object.assign({}, state, {
        selfManagedClients: action.payload
      });
    case 'SET_IS_LOADING_SELF_MANAGED_CLIENTS':
      return Object.assign({}, state, {
        isLoadingSelfManagedClients: action.payload
      });
    case 'SET_SELECTED_CLIENT':
      return Object.assign({}, state, {
        selectedClient: action.payload
      });
    case 'SET_IS_LOADING_ADMIN_CLIENTS':
      return Object.assign({}, state, { isLoading: true });
    case 'SET_SELECTED_CLIENT_SUBSCRIPTIONS':
      return Object.assign({}, state, {
        selectedClientSubscriptions: action.payload
      });
    case 'SET_CURRENT_CLIENT_SUBSCRIPTION':
      return Object.assign({}, state, {
        currentClientSubscription: action.payload,
        currentClientSubscriptionType: action.payload?.type
      });
    case 'SET_CURRENT_CLIENT_SUBSCRIPTIONS':
      return Object.assign({}, state, {
        currentClientSubscriptions: action.payload
      });
    case 'SET_CURRENT_CLIENT_FEATURE_FLAGS':
      return Object.assign({}, state, {
        currentClientFeatureFlags: action.payload
      });
    default:
      return state;
  }
};

export default adminClients;

export const selectSubscriptions = state =>
  state.adminClients.currentClientSubscriptions;

export const selectIsBulkUpdateByViewEnabled = state =>
  state.adminClients?.currentClientFeatureFlags?.['BULK_UPDATE_BY_VIEW']
    ?.enabled;
