const initialState = {
  orphanedEmails: {}
};

const emails = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ORPHANED_EMAILS':
      let orphanedEmails = {
        data: []
      };
      if (action.payload._embedded) {
        orphanedEmails = {
          data: action.payload._embedded.emails,
          links: action.payload._links,
          paging: action.payload.page
        };
      } else if (action.payload.length) {
        orphanedEmails = {
          data: action.payload
        };
      }
      return Object.assign({}, state, {
        isLoading: false,
        orphanedEmails: orphanedEmails
      });
    case 'SET_IS_LOADING_ORPHANED_EMAILS':
      return Object.assign({}, state, { isLoading: true });
    default:
      return state;
  }
};

export default emails;
