import { Auth, Hub } from 'aws-amplify';

import { portalConfig } from '../Config/config';
import { Toast as toast } from '../Shared/Toast/Toast';
import { initializers } from './initializers';

const cognitoInitializer = () => {
  // https://aws-amplify.github.io/docs/js/hub
  Hub.listen(/.*/, ({ channel, payload }) => {
    // eslint-disable-next-line testing-library/no-debugging-utils
    window.console.debug(`DEBUG [hub::${channel}::${payload.event}]`, payload);

    if (payload.event === 'tokenRefresh_failure') {
      toast.warn('Your session has expired. You will need to sign in again.', {
        toastId: 'TOKEN_REFRESH_FAILURE'
      });
    }
  });

  const oauthConfig = {
    domain: portalConfig.COGNITO_OAUTH_DOMAIN,
    redirectSignIn: `${document.location.origin}/sso-return`,
    redirectSignOut: `${document.location.origin}/sso-signout`,
    responseType: 'code',
    scope: ['email', 'profile', 'openid']
  };
  const authConfig = {
    federationTarget: 'COGNITO_USER_POOLS',
    region: 'us-east-1',
    userPoolId: portalConfig.COGNITO_POOL_ID,
    userPoolWebClientId: portalConfig.COGNITO_CLIENT_ID
  };

  Auth.configure({
    Auth: authConfig,
    oauth: oauthConfig
  });
};

initializers.push(cognitoInitializer);
