const initialState = {
  looks: []
};

const reporting = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOOKS':
      return Object.assign({}, state, {
        looks: action.payload
          ? action.payload.map(look => {
              return {
                label: look.look.title,
                short_url: look.look.short_url,
                value: look.look.id
              };
            })
          : []
      });
    default:
      return state;
  }
};

export default reporting;
