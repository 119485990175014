const initialState = {
  currentEditWatch: undefined,
  currentEditWatchName: { queryName: undefined },
  isLoading: false,
  paging: {},
  results: [],
  viewWatchName: '',
  viewWatchOfferings: '',
  watchError: ''
};

const watches = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_WATCH':
      return Object.assign({}, state, {
        results: [...state.results, action.payload]
      });
    case 'SET_ALL_WATCHES':
      return Object.assign({}, state, {
        isLoading: false,
        links: action.payload._links,
        paging: action.payload.page,
        results: action.payload._embedded
          ? action.payload._embedded.watches
          : []
      });
    case 'SET_CURRENT_EDIT_WATCH':
      return Object.assign({}, state, {
        currentEditWatch: {
          ...action.payload,
          brand: { label: action.payload.brand, value: action.payload.brandId },
          ipGroupIds: action.payload.config.ipGroupIds?.map(value => {
            return { label: value, value: value };
          }),
          ipGroups: action.payload.config.ipGroups?.map(value => {
            return { label: value, value: value };
          }),
          ipGroupsNotApplicable: action.payload.config.ipGroupsNotApplicable,
          query: {
            query: [...action.payload.config.clauses]
          }
        },
        currentEditWatchName: { queryName: action.payload.name }
      });
    case 'CLEAR_CURRENT_EDIT_WATCH':
      return Object.assign({}, state, {
        currentEditWatch: undefined,
        currentEditWatchName: { queryName: undefined }
      });

    case 'SET_IS_LOADING_WATCHES':
      return Object.assign({}, state, { isLoading: true });
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default watches;
