const testStoreData = {
  defaultStores: [],
  storeData: []
};

const STORES_TO_REMOVE = ['1Mobile'];

const stores = (state = testStoreData, action) => {
  switch (action.type) {
    case 'GET_STORES':
      return Object.assign({}, state, {
        storeData: action.payload
          ? action.payload.filter(
              store => !STORES_TO_REMOVE.includes(store.name)
            )
          : state.storeData
      });
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default stores;
