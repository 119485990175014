import React from 'react';

import maintenanceComponentSvg from './maintenance-component.svg';

const MaintenanceComponent = () => {
  return (
    <div className="MaintenanceComponent">
      <img
        alt="Maintenance"
        className="MaintenanceComponent-svg"
        src={maintenanceComponentSvg}
      />
    </div>
  );
};

export default MaintenanceComponent;
