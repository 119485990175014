import moment from 'moment-timezone';
import 'moment-timezone/builds/moment-timezone-with-data.min';
import momentLocalizer from 'react-widgets-moment';

import { initializers } from './initializers';

const momentInitializer = () => {
  moment.tz.setDefault(moment.tz.guess(true));
  moment.locale('en');
  momentLocalizer(moment);
};

initializers.push(momentInitializer);
