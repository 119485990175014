export const developmentConfig = Object.freeze({
  COGNITO_CLIENT_ID: '582giucuqb1gvg2o3bumsp4b98',
  COGNITO_OAUTH_DOMAIN: 'sso-auth.test-cloud.appdetex.com',
  COGNITO_POOL_ID: 'us-east-1_tHhhasTwb',
  REACT_APP_AUTH_URL: 'https://auth.test-cloud.appdetex.com',
  REACT_APP_DOMAIN: 'localhost',
  REACT_APP_GA_SHOULD_DEBUG: false,
  REACT_APP_HOST_ENV: 'development',
  REACT_APP_INFO_URL: 'https://infoa.test-cloud.appdetex.com',
  REACT_APP_LOOKER_HOST: 'looker.tracer.ai',
  REACT_APP_LOOKER_SECRET:
    '45c3e3b0a981fb12fbab76fee52d235e020692cd2e9a27b9a82d07640614b94d',
  REACT_APP_WAITS_URL: 'https://waits.test-cloud.appdetex.com'
  // REDIRECT_BETA_URL: 'https://portal-feature.test-cloud.tracer.ai'
});
