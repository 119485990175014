import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import domainReducers from '../Domain/store/domain.reducer';
import { mobileReducers } from '../Mobile/store/mobile.reducer';
import { accounts } from './accounts/accounts.reducer';
import activities from './activities/activities.reducer';
import activityPanel from './activityPanel/activityPanel.reducer';
import adminClients from './adminClients/adminClients.reducer';
import adminUsers from './adminUsers/adminUsers.reducer';
import advancedSearch from './advancedSearch/advancedSearch.reducer';
import { aiSettings } from './aiSettings/aiSettings.reducer';
import apiManagement from './apiManagement/apiManagement.reducer';
import { brands } from './brands/brands.reducer';
import { brandtracks } from './brandtracks/brandtracks.reducer';
import categories from './categories/categories.reducer';
import currency from './currency/currency.reducer';
import customization from './customization/customization.reducer';
import detailsCardData from './detailsCard/detailsCardData.reducer';
import detections from './detections/detections.reducer';
import drafts from './drafts/drafts.reducer';
import emails from './emails/emails.reducer';
import enfCase from './enfCase/enfCase.reducer';
import enforcements from './enforcements/enforcements.reducer';
import filteredTable from './filteredTable/filteredTable.reducer';
import filters from './filters/filters.reducer';
import { imports } from './imports/imports.reducer';
import jobs from './jobs/jobs.reducer';
import labels from './labels/labels.reducer';
import notifications from './notifications/notifications.reducer';
import permissions from './permissions/permissions.reducer';
import platformCredentials from './platformCredentials/platformCredentials.reducer';
import reporting from './reporting/reporting.reducer';
import roles from './roles/roles.reducer';
import savedViews from './savedViews/savedViews.reducer';
import stores from './stores/stores.reducer';
import { userReducer } from './user/user.reducer';
import watches from './watches/watches.reducer';

export const rootReducers = combineReducers({
  accounts,
  activities,
  activityPanel,
  adminClients,
  adminUsers,
  advancedSearch,
  aiSettings,
  apiManagement,
  app: combineReducers({ ...mobileReducers }),
  brands,
  brandtracks,
  categories,
  currency,
  customization,
  detailsCardData,
  detections,
  domain: combineReducers({ ...domainReducers }),
  drafts,
  emails,
  enfCase,
  enforcements,
  filteredTable,
  filters,
  form: formReducer,
  imports,
  jobs,
  labels,
  notifications,
  permissions,
  platformCredentials,
  reporting,
  roles,
  savedViews,
  stores,
  user: userReducer,
  watches
});
