import React from 'react';

import { ToastCloseButton, Toast as toast } from '../Shared/Toast/Toast';
import { initializers } from './initializers';

const toastInitializer = () =>
  toast.configure({
    autoClose: 5000,
    closeButton: <ToastCloseButton />,
    hideProgressBar: true,
    pauseOnFocusLoss: false
  });

initializers.push(toastInitializer);
