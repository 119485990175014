import axios from 'axios';
import has from 'has';

import AppdetexAuth from '../../Services/Auth';
import store from '../../Services/Store';

export const getUsers = (url, searchString) => {
  return dispatch => {
    dispatch(setIsLoadingAdminUsers());
    return AppdetexAuth.getUsers(url, searchString)
      .then(res => {
        return dispatch(setAdminUsers(res.data));
      })
      .catch(() => {
        return dispatch(setAdminUsers([]));
      });
  };
};

export const clearUsers = () => {
  return dispatch => {
    dispatch(setAdminUsers([]));
  };
};

export const getUser = url => {
  return dispatch => {
    return AppdetexAuth.getSpecifiedUser(url)
      .then(res => {
        return dispatch(selectUser(res.data));
      })
      .catch(() => {
        return dispatch(selectUser(undefined));
      });
  };
};

export const getUserSubscriptions = user => {
  const subscriptionLink = user?._links?.getSubscriptions?.href;
  return dispatch => {
    return axios
      .get(subscriptionLink)
      .then(res => {
        return dispatch(
          setSelectedUserSubscriptions(res.data._embedded?.subscriptions ?? [])
        );
      })
      .catch(err => {
        console.warn('Unable to retrieve user subscriptions', err);
      });
  };
};

export const getUserClients = id => {
  return dispatch => {
    return AppdetexAuth.getUserClients(id)
      .then(res => {
        return dispatch(setUserClients(res.data));
      })
      .catch(() => {
        return dispatch(setUserClients([]));
      });
  };
};

export const changePage = (verb, pageNumber) => {
  const links = store.getState().adminUsers.users._links;
  let url;
  if (verb) {
    url = has(links, verb) ? links[verb].href : links.self.href;
  } else {
    url = links.self.href;
    if (url.includes('page')) {
      url = url.replace(/page=\d+/, `page=${pageNumber}`);
    } else {
      url = `${url}${url.includes('?') ? '&' : '?'}page=${pageNumber}`;
    }
  }
  return dispatch => {
    dispatch(setIsLoadingAdminUsers());
    return AppdetexAuth.getUsers(url).then(res => {
      return dispatch(setAdminUsers(res.data));
    });
  };
};

export const setUserClients = clients => {
  return {
    payload: clients,
    type: 'SET_USER_CLIENTS'
  };
};

export const setAdminUsers = users => {
  return {
    payload: users,
    type: 'SET_ADMIN_USERS'
  };
};

export const selectUser = user => {
  return {
    payload: user,
    type: 'SELECT_USER'
  };
};

export const setSelectedUserSubscriptions = subscriptions => {
  return {
    payload: subscriptions,
    type: 'SET_SELECTED_USER_SUBSCRIPTIONS'
  };
};

export const setIsLoadingAdminUsers = () => {
  return {
    type: 'SET_IS_LOADING_ADMIN_USERS'
  };
};

export const setFormErrors = errors => {
  return {
    payload: errors,
    type: 'SET_FORM_ERRORS'
  };
};
