const initialState = {
  draft: null,
  form: null,
  preview: null
};

const enforcements = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ENFORCEMENT_FORM':
      return Object.assign({}, state, {
        form: action.payload
      });
    case 'SET_ENFORCEMENT_PREVIEW':
      return Object.assign({}, state, {
        preview: action.payload
      });
    case 'SET_ENFORCMENT_DRAFT':
      return Object.assign({}, state, {
        draft: action.payload
      });
    default:
      return state;
  }
};

export default enforcements;
