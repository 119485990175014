import { viewModeEnum } from '../../Utils/detection-utils.js';

const initialState = {
  closedReasonPopover: {
    isOpen: false,
    offeringUrl: '',
    prevValue: ''
  },
  columns: [],
  detections: [],
  filters: [],
  isLoading: false,
  links: {},
  page: {},
  searchTerm: {},
  viewMode: viewModeEnum.table
};

export const detections = (state = initialState, { payload, type }) => {
  switch (type) {
    case 'SET_DETECTIONS':
      return {
        ...state,
        detections: payload.searchResults ?? state.searchResults,
        isLoading: false,
        links: payload.links
          ? payload.links.reduce(
              (prev, curr) => ({ ...prev, [curr.rel]: { href: curr.href } }),
              {}
            )
          : state.links,
        page: payload.page ?? state.page
      };
    case 'SET_COLUMNS':
      return {
        ...state,
        columns: payload,
        isLoading: false
      };
    case 'SET_FILTERS':
      return {
        ...state,
        filters: payload,
        isLoading: false
      };
    case 'SET_IS_LOADING_DETECTIONS':
      return {
        ...state,
        isLoading: true
      };
    case 'SET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: payload
      };
    case 'SET_VIEW_MODE':
      return {
        ...state,
        viewMode: payload
      };
    case 'SET_CLOSED_REASON_POPOVER':
      return {
        ...state,
        closedReasonPopover: { ...state.closedReasonPopover, ...payload }
      };
    default:
      return state;
  }
};

// disabling eslint no default export as this refactor is sizeable
// eslint-disable-next-line import/no-default-export
export default detections;
