import axios from 'axios';

import { portalConfig } from '../Config/config';
import {
  addCasePermissions,
  addPermissions
} from '../store/permissions/permissions.actions';
import store from './Store';

/**
 * @type {String}
 */
const waitsUrl = portalConfig.REACT_APP_WAITS_URL;

/**
 *
 * @param {String|Number} accountId
 * @returns {Promise}
 */
export const getRoot = accountId =>
  axios
    .get(`${waitsUrl}?accountId=${encodeURIComponent(accountId)}`)
    .then(res => {
      if (res.data._embedded) {
        const permissions = Object.assign({}, res.data._links);
        Object.keys(res.data._embedded).map(
          key => (permissions[key] = res.data._embedded[key]._links)
        );

        store.dispatch(addPermissions(permissions));
      }
      return res;
    });

/**
 *
 * @param {String|Number} accountId
 * @returns {Promise}
 */
export const getCaseRoot = accountId =>
  axios.get(`${waitsUrl}/case/${accountId}`).then(res => {
    if (res.data._links) {
      const permissions = res.data._embedded
        ? Object.assign(
            res.data._links,
            res.data._embedded.app._links,
            Object.keys(res.data._embedded).reduce((perms, key) => {
              perms[key] = res.data._embedded[key]._links;
              return perms;
            }, {})
          )
        : res.data._links;
      store.dispatch(addCasePermissions(permissions));
    } else if (res.data._embedded) {
      store.dispatch(addCasePermissions(res.data._embedded.app._links));
    }
    return res;
  });
