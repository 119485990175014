import Bugsnag from '@bugsnag/js';
import React from 'react';
import ReactDOM from 'react-dom';

import { portalConfig } from './Config/config';
import './Initializers/index';
import RouterRoot from './RouterRoot';
import { ErrorComponent } from './Utils/ErrorComponent';
import './index.scss';

console.warn('ENV', portalConfig.REACT_APP_HOST_ENV);

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  ['production'].includes(portalConfig.REACT_APP_HOST_ENV) ? (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <RouterRoot />
    </ErrorBoundary>
  ) : (
    <RouterRoot />
  ),
  document.getElementById('root')
);
