const initialState = {
  isAuthenticated: null,
  isReady: null
};

export const userReducer = (state = initialState, action) => {
  state = { ...initialState, ...state };

  switch (action.type) {
    case 'SET_IS_AUTHENTICATED':
      return { ...state, isAuthenticated: action.payload };
    case 'SET_IS_READY':
      return { ...state, isReady: action.payload };
    case 'SET_USER':
      const hasTracerAccount = action.payload?._embedded?.accountRoles.some(
        role => role.accountId === 1
      );
      // TODO is this actually a reliable way to detect if it's an Appdetex employee
      const email = action.payload?.email;
      const isTracerEmployee = hasTracerAccount && email.includes('@tracer.ai');

      const isLexisNexisEmployee =
        email?.includes('@lexisnexis.co.uk') ||
        email?.includes('@lexisnexis.com');

      const isInitialized = true;

      return {
        ...state,
        ...action.payload,
        hasTracerAccount,
        isInitialized,
        isLexisNexisEmployee,
        isTracerEmployee
      };
    case 'USER_FORM_ERROR':
      let errors = {};
      if (action?.payload?.errors?.length)
        action.payload.errors.map(error => {
          return (errors[error.field] =
            error.defaultMessage.charAt(0).toUpperCase() +
            error.defaultMessage.substring(1));
        });

      if (!Object.keys(errors).length) {
        if (action?.payload?.errors?.length) {
          errors = action.payload.errors
            .map(error => `${error.field} - ${error.defaultMessage}`)
            .join('<br />');
        } else if (action?.payload?.message) {
          errors = action.payload.message;
        }
      }
      return Object.assign(state, { errors });
    default:
      return state;
  }
};

export const selectUser = state => state.user;
