import axios from 'axios';
import { GraphQLClient } from 'graphql-request';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';

import { portalConfig } from '../Config/config';
import { Toast as toast } from '../Shared/Toast/Toast';
import { getActiveModule, getCurrentAppName } from '../Utils/PortalProducts';
import { exchangeCognitoJwtForAppdetexJwt, getCognitoJwtToken } from './Auth2';
import { getDecodedToken, getToken } from './BearerToken';
import store from './Store';
import { getAccountId } from './Waits';

const waitsUrl = portalConfig.REACT_APP_WAITS_URL;

export const getCurrentQuery = () => {
  let query = {};
  try {
    query = JSON.parse(sessionStorage.getItem('adxCurrentQuery'));
  } catch (e) {
    /* no - op */
  }
  return { ...query };
};

const getPreferredCurrency = () =>
  store.getState().currency?.preferredCurrency ?? null;

const setCurrentQuery = query => {
  sessionStorage.setItem('adxCurrentQuery', JSON.stringify(query));
};

/**
 * @async
 * @returns GraphQLClient instance
 */
const getGraphQLClient = async () => {
  const decodedToken = getDecodedToken();

  if (decodedToken.exp && moment.unix(decodedToken.exp).isBefore(moment())) {
    const newCognitoToken = await getCognitoJwtToken();
    await exchangeCognitoJwtForAppdetexJwt(newCognitoToken);
  }
  return new GraphQLClient(`${waitsUrl}/graphql/api`, {
    headers: {
      authorization: `Bearer ${getToken()}`
    }
  });
};

export const defaultColumns = {
  brand: [
    {
      displayName: 'Image',
      field: 'genericImage'
    },
    {
      displayName: 'Name',
      field: 'genericName'
    },
    {
      displayName: 'Owner',
      field: 'genericOwner'
    },
    { displayName: 'Product', field: 'product' },
    { displayName: 'Platform', field: 'genericPlatform' },
    { displayName: 'Popularity', field: 'genericPopularity' },
    { displayName: 'Stage', field: 'stage' },
    { displayName: 'Labels', field: 'labels' },
    { displayName: 'Brand Tracks', field: 'watches' },
    {
      displayName: 'Alert Date',
      field: 'addedToWatch'
    },
    { displayName: 'Status', field: 'offeringStatus' }
  ],
  domain: [
    { displayName: 'Image', field: 'genericImage' },
    { displayName: 'Name', field: 'genericName' },
    { displayName: 'URL', field: 'url' },
    { displayName: 'Status', field: 'offeringStatus' },
    { displayName: 'Stage', field: 'stage' },
    { displayName: 'Labels', field: 'labels' },
    { displayName: 'Brand Tracks', field: 'watches' },
    { displayName: 'Alert Date', field: 'addedToWatch' }
  ],
  marketplaces: [
    { displayName: 'Image', field: 'genericImage' },
    { displayName: 'Name', field: 'genericName' },
    { displayName: 'Description', field: 'genericDescription' },
    { displayName: 'Owner', field: 'mrkOwner' },
    { displayName: 'Platform', field: 'genericPlatform' },
    { displayName: 'Locale', field: 'genericLocale' },
    { displayName: 'Popularity', field: 'genericPopularity' },
    { displayName: 'Price', field: 'mrkPrice' },
    { displayName: 'Stage', field: 'stage' },
    { displayName: 'Labels', field: 'labels' },
    { displayName: 'Brand Tracks', field: 'watches' },
    { displayName: 'Alert Date', field: 'addedToWatch' },
    { displayName: 'Status', field: 'offeringStatus' }
  ],
  mobile: [
    { displayName: 'Image', field: 'genericImage' },
    { displayName: 'Name', field: 'genericName' },
    { displayName: 'Description', field: 'description' },
    { displayName: 'Owner', field: 'genericOwner' },
    { displayName: 'Platform', field: 'genericPlatform' },
    { displayName: 'Stage', field: 'stage' },
    { displayName: 'Downloads', field: 'downloads' },
    { displayName: 'Labels', field: 'labels' },
    { displayName: 'Brand Tracks', field: 'watches' },
    { displayName: 'Alert Date', field: 'addedToWatch' },
    { displayName: 'Status', field: 'offeringStatus' }
  ],
  search_engine: [
    { displayName: 'Image', field: 'genericImage' },
    { displayName: 'Name', field: 'genericName' },
    { displayName: 'Description', field: 'genericDescription' },
    { displayName: 'Owner', field: 'genericOwner' },
    { displayName: 'Platform', field: 'genericPlatform' },
    { displayName: 'Content Type', field: 'platformType' },
    { displayName: 'Popularity', field: 'genericPopularity' },
    { displayName: 'Ad Source', field: 'searchEngineAdSource' },
    { displayName: 'Ad Type', field: 'searchEngineAdType' },
    { displayName: 'Stage', field: 'stage' },
    { displayName: 'Downloads', field: 'downloads' },
    { displayName: 'Labels', field: 'labels' },
    { displayName: 'Brand Tracks', field: 'watches' },
    { displayName: 'Alert Date', field: 'addedToWatch' }
  ],
  social: [
    { displayName: 'Image', field: 'genericImage' },
    { displayName: 'Name', field: 'genericName' },
    { displayName: 'Platform', field: 'genericPlatform' },
    { displayName: 'Content Type', field: 'platformType' },
    { displayName: 'Owner', field: 'genericOwner' },
    { displayName: 'Status', field: 'offeringStatus' },
    { displayName: 'Stage', field: 'stage' },
    { displayName: 'Labels', field: 'labels' },
    { displayName: 'Brand Tracks', field: 'watches' },
    { displayName: 'Alert Date', field: 'addedToWatch' }
  ]
};

export const standardColumns = ['btn', 'checkbox', 'index', 'networks'];

export const getFilterMetadata = async () => {
  try {
    const result = await axios.get(
      `${waitsUrl}/fields/${getAccountId()}/${getCurrentAppName()}`
    );
    return result?.data?._embedded?.detectionFieldResourceList;
  } catch (e) {
    throw Error(e);
  }
};

const getUrlsFromSearch = search => {
  let searchUrls;
  if (
    search?.search?.match(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/
    )
  ) {
    searchUrls = [search.search];
  } else if (search?.bulkSearch) {
    searchUrls = search.bulkSearch.map(url => url.trim());
  }
  return searchUrls;
};

/**
 * Search for detections by current account and app
 * @param {Object[]} columns
 * @param {String} columns[].displayName
 * @param {String} columns[].field
 * @param {String} columns[].indexKey
 * @param {Object[]} filters
 * @param {String} filters[].field
 * @param {String} filters[].op
 * @param {Array} filters[].terms
 * @param {Object} page
 * @param {Number} page.number
 * @param {Number} page.size
 * @param {String} setQuery GraphQL query
 * @param {Object} search
 * @param {String} search.search search URL
 * @param {String} search.bulkSearch
 * @param {Object} sort
 * @param {String} sort.sortBy
 * @param {String} sort.field
 * @param {String} sort.sortDirection one of ASC or DESC
 * @returns {{accountSearch: Object, variables: Object, columns: Array}{}}
 */
export const accountSearch = async (
  columns = [],
  { filters = [], logicalOperator = 'AND' },
  page = {},
  setQuery,
  search = {},
  sort = {},
  shouldSetCurrentQuery = true
) => {
  // Remove any standardColumns from columns
  columns = columns?.filter(column => !standardColumns.includes(column.field));
  // If columns array is not an array or empty, get them from
  // redux or use the defaults for the current product
  if (!Array.isArray(columns) || !columns.length) {
    columns = store.getState().detections?.columns?.length
      ? store.getState().detections?.columns
      : defaultColumns[getActiveModule().name];
  }

  const graphQLClient = await getGraphQLClient();

  const query =
    setQuery ??
    `query getAccountSearch($accountId: Long! $viewType: ViewType! $filter: FilterInput $searchTerm: String $urls: [String!] $page: Int! $pageSize: Int! $sort: [SortInput!] $preferredCurrency: String) {
    accountSearch(
      accountId: $accountId,
      viewType: $viewType,
      filter: $filter,
      searchTerm: $searchTerm,
      urls: $urls,
      page: $page,
      pageSize: $pageSize,
      sort: $sort,
      preferredCurrency: $preferredCurrency
    ) {
      searchResults {
        uid
        offeringStatus
        links
        documentType
        url
        ${getCurrentAppName() === 'SEARCH_ENGINE' ? 'targetUrl' : ''}
        whitelists
        ${columns?.map(column => column.field).join('\n')}
      }
      page {
        size
        totalElements
        totalPages
        number
      }
      links
    }
  }`;

  if (!filters) {
    filters = [];
  }

  const searchUrls = getUrlsFromSearch(search);
  const variables = {
    accountId: getAccountId(),
    filter: { filters: filters ?? [], logicalOperator: logicalOperator },
    page: page?.number ?? 0,
    pageSize: page?.size ?? 200,
    preferredCurrency: getPreferredCurrency(),
    searchTerm: searchUrls ? null : search?.search,
    sort: sort?.sortBy || sort?.field ? [sort] : undefined,
    urls: searchUrls,
    viewType: getCurrentAppName()
  };

  if (shouldSetCurrentQuery) {
    setCurrentQuery({ columns, query, variables });
  }

  try {
    const result = await graphQLClient.request(query, variables);
    return { accountSearch: result.accountSearch, columns, variables };
  } catch (e) {
    toast.error('Failed to load detections');
    throw Error(e);
  }
};

export const accountSearchWithQuery = async (query, variables, columns) => {
  const graphQLClient = await getGraphQLClient();
  variables.preferredCurrency = getPreferredCurrency();

  setCurrentQuery({ columns, query, variables });

  try {
    const result = await graphQLClient.request(query, variables);
    return { accountSearch: result.accountSearch, variables };
  } catch (e) {
    toast.error('Failed to load detections');
    throw Error(e);
  }
};

/**
 * Run last used query when returning to a detections view
 * @returns `{ response, variables, columns }`
 */
export const runCurrentQuery = async () => {
  if (isEmpty(getCurrentQuery())) {
    const result = await accountSearch(
      [],
      { filters: [], logicalOperator: 'AND' },
      {},
      null,
      {},
      false
    );
    return result;
  }

  const graphQLClient = await getGraphQLClient();

  try {
    const currentQuery = getCurrentQuery();
    currentQuery.variables.preferredCurrency = getPreferredCurrency();
    const result = await graphQLClient.request(
      currentQuery.query,
      currentQuery.variables
    );
    result.columns = currentQuery.columns;
    result.variables = currentQuery.variables;
    return result;
  } catch (e) {
    toast.error('Failed to load detections');
    throw Error(e);
  }
};

export const schemaRequest = () => {
  axios.get(`${waitsUrl}/graphql/api/sdl`);
};

export const exportAccountSearch = async (
  filename,
  includes,
  filters = [],
  sort = {},
  search = {}
) => {
  if (!includes.includes('url')) {
    includes.push('url');
  }
  const graphQLClient = await getGraphQLClient();
  const query = `query postExportAccountSearch($accountId: Long! $viewType: ViewType! $filename: String! $includes: [String!]! $filter: FilterInput $sort: [SortInput!] $searchTerm: String $urls: [String!], $preferredCurrency: String) {
    exportAccountSearch(
      accountId: $accountId,
      viewType: $viewType,
      filename: $filename,
      includes: $includes,
      filter: $filter,
      sort: $sort
      searchTerm: $searchTerm,
      urls: $urls,
      preferredCurrency: $preferredCurrency
    ) {
      userId,
      s3Path,
      filename,
      portalDownloadUrl
    }
  }`;

  const searchUrls = getUrlsFromSearch(search);
  const variables = {
    accountId: getAccountId(),
    filename: filename,
    filter: filters,
    includes: includes,
    preferredCurrency: getPreferredCurrency(),
    searchTerm: searchUrls ? null : search?.search,
    sort: sort?.sortBy || sort?.field ? [sort] : undefined,
    urls: searchUrls,
    viewType: getCurrentAppName()
  };

  try {
    return graphQLClient.request(query, variables);
  } catch (e) {
    toast.error('Failed to export detections');
  }
};

export const exportAccountSearchEmail = async (uids, to, cc, comments) => {
  const graphQLClient = await getGraphQLClient();
  const query = `query postExportAccountSearchEmail($accountId: Long! $uids: ExportEmailUids! $to: [String!]! $cc: [String!] $comments: String) {
    exportAccountSearchEmail(
      accountId: $accountId,
      uids: $uids,
      to: $to,
      cc: $cc,
      comments: $comments      
    ) 
  }`;

  const variables = {
    accountId: getAccountId(),
    cc: cc,
    comments: comments,
    to: to,
    uids: uids
  };

  try {
    return graphQLClient.request(query, variables);
  } catch (e) {
    toast.error('Failed to export detections');
  }
};
