const initialState = {
  isLoading: false,
  links: { self: null },
  paging: {},
  results: []
};

export const brandtracks = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DOMAIN_PRESET_CONFIGS':
      return Object.assign({}, state, { configs: action.payload });
    case 'SET_SOCIAL_FILTER_FIELDS':
      return Object.assign({}, state, { socialFilterFields: action.payload });
    case 'SET_MARKETPLACES_FILTER_FIELDS':
      return Object.assign({}, state, {
        marketplacesFilterFields: action.payload
      });
    case 'SET_DOMAIN_FILTER_FIELDS':
      return Object.assign({}, state, {
        domainFilterFields: action.payload
      });
    case 'SET_SEARCH_ENGINE_FILTER_FIELDS':
      return Object.assign({}, state, {
        searchEngineFilterFields: action.payload
      });
    case 'SET_MARKETPLACES_PLATFORMS':
      return Object.assign({}, state, {
        marketplacesPlatforms: action.payload
      });
    case 'SET_SEARCH_ENGINE_PLATFORMS':
      return Object.assign({}, state, {
        searchEnginePlatforms: action.payload
      });
    case 'SET_SEARCH_ENGINE_LOCALES':
      return Object.assign({}, state, {
        searchEngineLocales: action.payload
      });
    case 'SET_SOCIAL_PLATFORMS':
      return Object.assign({}, state, {
        socialPlatforms: action.payload?.map(platform => ({
          label: `${platform.displayName}`,
          value: platform.name
        }))
      });
    case 'SET_BRAND_TRACKS':
      let brandTracks = action.payload._embedded?.brandtracks;
      brandTracks = brandTracks?.map(brandTrack => {
        brandTrack['ipGroups'] = brandTrack.config?.ipGroups;
        delete brandTrack.config;
        return brandTrack;
      });
      return Object.assign({}, state, {
        isLoading: false,
        links: action.payload._links,
        paging: action.payload.page,
        results: brandTracks || []
      });
    case 'SET_IS_LOADING_BRANDTRACKS':
      return Object.assign({}, state, { isLoading: true });
    case 'CLEAR_SELF_LINK':
      state.links.self = null;
      return Object.assign({}, state);
    default:
      return state;
  }
};
