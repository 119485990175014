import axios from 'axios';
import { isEqual } from 'lodash';

import { portalConfig } from '../Config/config';
import { getCaseRoot, getRoot } from '../Services/RootResource';
import {
  getPortalProductBySubscriptionType,
  getSubscribedPortalProducts,
  isUserSubscribed
} from '../Utils/PortalProducts';
import {
  setCurrentAccount,
  setUserAccounts
} from '../store/accounts/accounts.actions';
import { setUser } from '../store/user/user.actions';
import AppdetexAuth from './Auth';
import { getAccountIdFromToken, getDecodedToken } from './BearerToken';
import {
  disableProductsBasedOnFeatureFlag,
  setFeatureFlags
} from './FeatureFlags';
import store from './Store';
import {
  setCurrentSubscriptionOnAppLoad,
  setSubscriptions
} from './Subscriptions';

/**
 * Get a user, send data to redux
 * @returns {Promise} `true`
 */
export const getUser = () =>
  axios
    .get(`${portalConfig.REACT_APP_AUTH_URL}/users/me`)
    .then(usersMeResponse => {
      const accountId = getAccountIdFromToken();

      const isSelfManaged = getDecodedToken()?.isSelfManaged;
      let currentAccount = usersMeResponse.data._embedded.accountRoles
        .filter(account => account.accountId === accountId)
        .shift();
      return AppdetexAuth.getAccount(accountId).then(res => {
        currentAccount = { ...currentAccount, ...res.data?._embedded };

        if (
          !isEqual(currentAccount, store.getState()?.accounts?.currentAccount)
        ) {
          store.dispatch(setCurrentAccount(currentAccount));
        }

        store.dispatch(setUser({ ...usersMeResponse.data, isSelfManaged }));
        store.dispatch(setUserAccounts(usersMeResponse.data));

        return disableProductsBasedOnFeatureFlag(currentAccount.accountId)
          .then(() =>
            setFeatureFlags(
              store.getState().accounts?.currentAccount?.accountId,
              ['TRACER_CONNECTIONS_AS_NODES', 'BULK_UPDATE_BY_VIEW']
            )
          )
          .then(() => setSubscriptions())
          .then(() => setCurrentSubscriptionOnAppLoad())
          .then(() => getRoot(currentAccount.accountId))
          .then(() => getCaseRoot(currentAccount.accountId))
          .then(() => true);
      });
    });

/**
 * Check for the following:
 * 1. User previously attempted to access an /export path,
 *    which they probably clicked on from an email.
 * 2. The most recent module
 * 3. The first module they're subscribed to
 *
 * If none of those work, throw an error.
 *
 * @returns {String} path to navigate to
 */
export const getUserDestination = () => {
  const storedExportPath = getStoredExportPath();
  const mostRecentSubscription = getPortalProductBySubscriptionType(
    store.getState().adminClients.currentClientSubscription.type
  );

  const subscriptions =
    store.getState().adminClients.currentClientSubscriptions;

  if (storedExportPath) {
    clearStoredExportPath();
    return storedExportPath;
  } else if (
    mostRecentSubscription &&
    isUserSubscribed(mostRecentSubscription, subscriptions)
  ) {
    return mostRecentSubscription.link;
  } else if (getSubscribedPortalProducts(subscriptions).length > 0) {
    return getSubscribedPortalProducts(subscriptions)[0].link;
  } else {
    window.sessionStorage.clear();
    window.localStorage.clear();
    throw new Error(
      'Could not find user destination. Check with your account manager to see if your account has been properly onboarded.'
    );
  }
};

/**
 * Storage key for most recent module
 * @type {String}
 */
const MOST_RECENT_MODULE_NAME_KEY = 'adxMostRecentModuleName';

/**
 * Set stored most recent module name
 * @param {String} name from name property in PortalProducts
 * @returns {void}
 */
export const storeMostRecentModuleName = name =>
  window.localStorage.setItem(MOST_RECENT_MODULE_NAME_KEY, name);

/**
 * Get stored most recent module name
 * @returns {String} name from name property in PortalProducts
 */
export const getStoredMostRecentModuleName = () =>
  window.localStorage.getItem(MOST_RECENT_MODULE_NAME_KEY);

/**
 * Storage key for  export path
 * @type {String}
 */
const EXPORT_PATH_KEY = 'adxExportPath';

/**
 * Stores export path
 * @param {String} exportPath
 * @returns {void}
 */
export const storeExportPath = exportPath =>
  window.sessionStorage.setItem(EXPORT_PATH_KEY, exportPath);

/**
 * Stores export path
 * @returns {String|null}
 */
const getStoredExportPath = () =>
  window.sessionStorage.getItem(EXPORT_PATH_KEY);

/**
 * Removes exportPath from sessionStorage
 * @returns {void}
 */
const clearStoredExportPath = () =>
  window.sessionStorage.removeItem(EXPORT_PATH_KEY);
